import { RepositoryPermission } from '@sourcegraph/shared/src/graphql-operations'
import { CampaignState } from '@sourcegraph/shared/src/graphql-operations'
import { ChangesetPublicationState } from '@sourcegraph/shared/src/graphql-operations'
import { ChangesetReconcilerState } from '@sourcegraph/shared/src/graphql-operations'
import { ChangesetExternalState } from '@sourcegraph/shared/src/graphql-operations'
import { ChangesetReviewState } from '@sourcegraph/shared/src/graphql-operations'
import { ChangesetCheckState } from '@sourcegraph/shared/src/graphql-operations'
import { ChangesetState } from '@sourcegraph/shared/src/graphql-operations'
import { ChangesetSpecOperation } from '@sourcegraph/shared/src/graphql-operations'
import { ChangesetSpecType } from '@sourcegraph/shared/src/graphql-operations'
import { BatchChangeState } from '@sourcegraph/shared/src/graphql-operations'
import { BulkOperationType } from '@sourcegraph/shared/src/graphql-operations'
import { BulkOperationState } from '@sourcegraph/shared/src/graphql-operations'
import { MonitorEmailPriority } from '@sourcegraph/shared/src/graphql-operations'
import { EventStatus } from '@sourcegraph/shared/src/graphql-operations'
import { LSIFUploadState } from '@sourcegraph/shared/src/graphql-operations'
import { LSIFIndexState } from '@sourcegraph/shared/src/graphql-operations'
import { EventSource } from '@sourcegraph/shared/src/graphql-operations'
import { SearchVersion } from '@sourcegraph/shared/src/graphql-operations'
import { SearchPatternType } from '@sourcegraph/shared/src/graphql-operations'
import { ExternalServiceKind } from '@sourcegraph/shared/src/graphql-operations'
import { SearchContextsNamespaceFilterType } from '@sourcegraph/shared/src/graphql-operations'
import { SearchContextsOrderBy } from '@sourcegraph/shared/src/graphql-operations'
import { DiffHunkLineType } from '@sourcegraph/shared/src/graphql-operations'
import { DiagnosticSeverity } from '@sourcegraph/shared/src/graphql-operations'
import { SymbolKind } from '@sourcegraph/shared/src/graphql-operations'
import { GitRefType } from '@sourcegraph/shared/src/graphql-operations'
import { GitRefOrder } from '@sourcegraph/shared/src/graphql-operations'
import { GitObjectType } from '@sourcegraph/shared/src/graphql-operations'
import { OrganizationInvitationResponseType } from '@sourcegraph/shared/src/graphql-operations'
import { RepositoryOrderBy } from '@sourcegraph/shared/src/graphql-operations'
import { AlertType } from '@sourcegraph/shared/src/graphql-operations'
import { UserEvent } from '@sourcegraph/shared/src/graphql-operations'
import { UserActivePeriod } from '@sourcegraph/shared/src/graphql-operations'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }

export interface WebGraphQlOperations {
    /** web/src/auth.ts */
    CurrentAuthState: (variables: CurrentAuthStateVariables) => CurrentAuthStateResult

    /** web/src/components/externalServices/backend.ts */
    AddExternalService: (variables: AddExternalServiceVariables) => AddExternalServiceResult

    /** web/src/components/externalServices/backend.ts */
    UpdateExternalService: (variables: UpdateExternalServiceVariables) => UpdateExternalServiceResult

    /** web/src/components/externalServices/backend.ts */
    SetExternalServiceRepos: (variables: SetExternalServiceReposVariables) => SetExternalServiceReposResult

    /** web/src/components/externalServices/backend.ts */
    ExternalService: (variables: ExternalServiceVariables) => ExternalServiceResult

    /** web/src/components/externalServices/backend.ts */
    AffiliatedRepositories: (variables: AffiliatedRepositoriesVariables) => AffiliatedRepositoriesResult

    /** web/src/components/externalServices/backend.ts */
    DeleteExternalService: (variables: DeleteExternalServiceVariables) => DeleteExternalServiceResult

    /** web/src/components/externalServices/backend.ts */
    ExternalServices: (variables: ExternalServicesVariables) => ExternalServicesResult

    /** web/src/enterprise/batches/close/backend.ts */
    CloseBatchChange: (variables: CloseBatchChangeVariables) => CloseBatchChangeResult

    /** web/src/enterprise/batches/detail/backend.ts */
    BatchChangeByNamespace: (variables: BatchChangeByNamespaceVariables) => BatchChangeByNamespaceResult

    /** web/src/enterprise/batches/detail/backend.ts */
    BatchChangeChangesets: (variables: BatchChangeChangesetsVariables) => BatchChangeChangesetsResult

    /** web/src/enterprise/batches/detail/backend.ts */
    SyncChangeset: (variables: SyncChangesetVariables) => SyncChangesetResult

    /** web/src/enterprise/batches/detail/backend.ts */
    ReenqueueChangeset: (variables: ReenqueueChangesetVariables) => ReenqueueChangesetResult

    /** web/src/enterprise/batches/detail/backend.ts */
    ExternalChangesetFileDiffs: (variables: ExternalChangesetFileDiffsVariables) => ExternalChangesetFileDiffsResult

    /** web/src/enterprise/batches/detail/backend.ts */
    ChangesetCountsOverTime: (variables: ChangesetCountsOverTimeVariables) => ChangesetCountsOverTimeResult

    /** web/src/enterprise/batches/detail/backend.ts */
    DeleteBatchChange: (variables: DeleteBatchChangeVariables) => DeleteBatchChangeResult

    /** web/src/enterprise/batches/detail/backend.ts */
    ChangesetDiff: (variables: ChangesetDiffVariables) => ChangesetDiffResult

    /** web/src/enterprise/batches/detail/backend.ts */
    ChangesetScheduleEstimate: (variables: ChangesetScheduleEstimateVariables) => ChangesetScheduleEstimateResult

    /** web/src/enterprise/batches/detail/backend.ts */
    DetachChangesets: (variables: DetachChangesetsVariables) => DetachChangesetsResult

    /** web/src/enterprise/batches/detail/backend.ts */
    CreateChangesetComments: (variables: CreateChangesetCommentsVariables) => CreateChangesetCommentsResult

    /** web/src/enterprise/batches/detail/backend.ts */
    BatchChangeBulkOperations: (variables: BatchChangeBulkOperationsVariables) => BatchChangeBulkOperationsResult

    /** web/src/enterprise/batches/detail/backend.ts */
    AllChangesetIDs: (variables: AllChangesetIDsVariables) => AllChangesetIDsResult

    /** web/src/enterprise/batches/list/backend.ts */
    BatchChanges: (variables: BatchChangesVariables) => BatchChangesResult

    /** web/src/enterprise/batches/list/backend.ts */
    BatchChangesByNamespace: (variables: BatchChangesByNamespaceVariables) => BatchChangesByNamespaceResult

    /** web/src/enterprise/batches/list/backend.ts */
    AreBatchChangesLicensed: (variables: AreBatchChangesLicensedVariables) => AreBatchChangesLicensedResult

    /** web/src/enterprise/batches/preview/backend.ts */
    BatchSpecByID: (variables: BatchSpecByIDVariables) => BatchSpecByIDResult

    /** web/src/enterprise/batches/preview/backend.ts */
    CreateBatchChange: (variables: CreateBatchChangeVariables) => CreateBatchChangeResult

    /** web/src/enterprise/batches/preview/backend.ts */
    ApplyBatchChange: (variables: ApplyBatchChangeVariables) => ApplyBatchChangeResult

    /** web/src/enterprise/batches/preview/list/backend.ts */
    BatchSpecApplyPreview: (variables: BatchSpecApplyPreviewVariables) => BatchSpecApplyPreviewResult

    /** web/src/enterprise/batches/preview/list/backend.ts */
    ChangesetSpecFileDiffs: (variables: ChangesetSpecFileDiffsVariables) => ChangesetSpecFileDiffsResult

    /** web/src/enterprise/batches/settings/backend.ts */
    CreateBatchChangesCredential: (
        variables: CreateBatchChangesCredentialVariables
    ) => CreateBatchChangesCredentialResult

    /** web/src/enterprise/batches/settings/backend.ts */
    DeleteBatchChangesCredential: (
        variables: DeleteBatchChangesCredentialVariables
    ) => DeleteBatchChangesCredentialResult

    /** web/src/enterprise/batches/settings/backend.ts */
    UserBatchChangesCodeHosts: (variables: UserBatchChangesCodeHostsVariables) => UserBatchChangesCodeHostsResult

    /** web/src/enterprise/batches/settings/backend.ts */
    GlobalBatchChangesCodeHosts: (variables: GlobalBatchChangesCodeHostsVariables) => GlobalBatchChangesCodeHostsResult

    /** web/src/enterprise/code-monitoring/backend.ts */
    CreateCodeMonitor: (variables: CreateCodeMonitorVariables) => CreateCodeMonitorResult

    /** web/src/enterprise/code-monitoring/backend.ts */
    ListUserCodeMonitors: (variables: ListUserCodeMonitorsVariables) => ListUserCodeMonitorsResult

    /** web/src/enterprise/code-monitoring/backend.ts */
    ToggleCodeMonitorEnabled: (variables: ToggleCodeMonitorEnabledVariables) => ToggleCodeMonitorEnabledResult

    /** web/src/enterprise/code-monitoring/backend.ts */
    FetchCodeMonitor: (variables: FetchCodeMonitorVariables) => FetchCodeMonitorResult

    /** web/src/enterprise/code-monitoring/backend.ts */
    UpdateCodeMonitor: (variables: UpdateCodeMonitorVariables) => UpdateCodeMonitorResult

    /** web/src/enterprise/code-monitoring/backend.ts */
    DeleteCodeMonitor: (variables: DeleteCodeMonitorVariables) => DeleteCodeMonitorResult

    /** web/src/enterprise/code-monitoring/backend.ts */
    ResetTriggerQueryTimestamps: (variables: ResetTriggerQueryTimestampsVariables) => ResetTriggerQueryTimestampsResult

    /** web/src/enterprise/code-monitoring/backend.ts */
    TriggerTestEmailAction: (variables: TriggerTestEmailActionVariables) => TriggerTestEmailActionResult

    /** web/src/enterprise/codeintel/configuration/backend.ts */
    IndexConfiguration: (variables: IndexConfigurationVariables) => IndexConfigurationResult

    /** web/src/enterprise/codeintel/configuration/backend.ts */
    UpdateRepositoryIndexConfiguration: (
        variables: UpdateRepositoryIndexConfigurationVariables
    ) => UpdateRepositoryIndexConfigurationResult

    /** web/src/enterprise/codeintel/configuration/backend.ts */
    QueueAutoIndexJobForRepo: (variables: QueueAutoIndexJobForRepoVariables) => QueueAutoIndexJobForRepoResult

    /** web/src/enterprise/codeintel/detail/backend.ts */
    LsifUpload: (variables: LsifUploadVariables) => LsifUploadResult

    /** web/src/enterprise/codeintel/detail/backend.ts */
    LsifIndex: (variables: LsifIndexVariables) => LsifIndexResult

    /** web/src/enterprise/codeintel/detail/backend.ts */
    DeleteLsifUpload: (variables: DeleteLsifUploadVariables) => DeleteLsifUploadResult

    /** web/src/enterprise/codeintel/detail/backend.ts */
    DeleteLsifIndex: (variables: DeleteLsifIndexVariables) => DeleteLsifIndexResult

    /** web/src/enterprise/codeintel/list/backend.ts */
    LsifUploadsForRepo: (variables: LsifUploadsForRepoVariables) => LsifUploadsForRepoResult

    /** web/src/enterprise/codeintel/list/backend.ts */
    LsifUploads: (variables: LsifUploadsVariables) => LsifUploadsResult

    /** web/src/enterprise/codeintel/list/backend.ts */
    CodeIntelligenceCommitGraphMetadata: (
        variables: CodeIntelligenceCommitGraphMetadataVariables
    ) => CodeIntelligenceCommitGraphMetadataResult

    /** web/src/enterprise/codeintel/list/backend.ts */
    LsifIndexesForRepo: (variables: LsifIndexesForRepoVariables) => LsifIndexesForRepoResult

    /** web/src/enterprise/codeintel/list/backend.ts */
    LsifIndexes: (variables: LsifIndexesVariables) => LsifIndexesResult

    /** web/src/enterprise/dotcom/productPlans/ProductPlanFormControl.tsx */
    ProductPlans: (variables: ProductPlansVariables) => ProductPlansResult

    /** web/src/enterprise/extensions/extension/RegistryExtensionManagePage.tsx */
    UpdateRegistryExtension: (variables: UpdateRegistryExtensionVariables) => UpdateRegistryExtensionResult

    /** web/src/enterprise/extensions/extension/RegistryExtensionNewReleasePage.tsx */
    PublishRegistryExtension: (variables: PublishRegistryExtensionVariables) => PublishRegistryExtensionResult

    /** web/src/enterprise/extensions/registry/RegistryNewExtensionPage.tsx */
    CreateRegistryExtension: (variables: CreateRegistryExtensionVariables) => CreateRegistryExtensionResult

    /** web/src/enterprise/extensions/registry/backend.ts */
    DeleteRegistryExtension: (variables: DeleteRegistryExtensionVariables) => DeleteRegistryExtensionResult

    /** web/src/enterprise/extensions/registry/backend.ts */
    ViewerRegistryPublishers: (variables: ViewerRegistryPublishersVariables) => ViewerRegistryPublishersResult

    /** web/src/enterprise/repo/settings/backend.ts */
    RepoPermissionsInfo: (variables: RepoPermissionsInfoVariables) => RepoPermissionsInfoResult

    /** web/src/enterprise/search/stats/backend.ts */
    SearchResultsStats: (variables: SearchResultsStatsVariables) => SearchResultsStatsResult

    /** web/src/enterprise/site-admin/SiteAdminAuthenticationProvidersPage.tsx */
    AuthProviders: (variables: AuthProvidersVariables) => AuthProvidersResult

    /** web/src/enterprise/site-admin/SiteAdminExternalAccountsPage.tsx */
    ExternalAccounts: (variables: ExternalAccountsVariables) => ExternalAccountsResult

    /** web/src/enterprise/site-admin/SiteAdminRegistryExtensionsPage.tsx */
    SiteAdminRegistryExtensions: (variables: SiteAdminRegistryExtensionsVariables) => SiteAdminRegistryExtensionsResult

    /** web/src/enterprise/site-admin/backend.ts */
    SiteAdminLsifUpload: (variables: SiteAdminLsifUploadVariables) => SiteAdminLsifUploadResult

    /** web/src/enterprise/site-admin/dotcom/customers/SiteAdminCustomerBillingLink.tsx */
    SetCustomerBilling: (variables: SetCustomerBillingVariables) => SetCustomerBillingResult

    /** web/src/enterprise/site-admin/dotcom/customers/SiteAdminCustomersPage.tsx */
    Customers: (variables: CustomersVariables) => CustomersResult

    /** web/src/enterprise/site-admin/dotcom/productSubscriptions/SiteAdminCreateProductSubscriptionPage.tsx */
    CreateProductSubscription: (variables: CreateProductSubscriptionVariables) => CreateProductSubscriptionResult

    /** web/src/enterprise/site-admin/dotcom/productSubscriptions/SiteAdminCreateProductSubscriptionPage.tsx */
    ProductSubscriptionAccounts: (variables: ProductSubscriptionAccountsVariables) => ProductSubscriptionAccountsResult

    /** web/src/enterprise/site-admin/dotcom/productSubscriptions/SiteAdminGenerateProductLicenseForSubscriptionForm.tsx */
    GenerateProductLicenseForSubscription: (
        variables: GenerateProductLicenseForSubscriptionVariables
    ) => GenerateProductLicenseForSubscriptionResult

    /** web/src/enterprise/site-admin/dotcom/productSubscriptions/SiteAdminProductLicensesPage.tsx */
    DotComProductLicenses: (variables: DotComProductLicensesVariables) => DotComProductLicensesResult

    /** web/src/enterprise/site-admin/dotcom/productSubscriptions/SiteAdminProductSubscriptionBillingLink.tsx */
    SetProductSubscriptionBilling: (
        variables: SetProductSubscriptionBillingVariables
    ) => SetProductSubscriptionBillingResult

    /** web/src/enterprise/site-admin/dotcom/productSubscriptions/SiteAdminProductSubscriptionPage.tsx */
    DotComProductSubscription: (variables: DotComProductSubscriptionVariables) => DotComProductSubscriptionResult

    /** web/src/enterprise/site-admin/dotcom/productSubscriptions/SiteAdminProductSubscriptionPage.tsx */
    ProductLicenses: (variables: ProductLicensesVariables) => ProductLicensesResult

    /** web/src/enterprise/site-admin/dotcom/productSubscriptions/SiteAdminProductSubscriptionPage.tsx */
    ArchiveProductSubscription: (variables: ArchiveProductSubscriptionVariables) => ArchiveProductSubscriptionResult

    /** web/src/enterprise/site-admin/dotcom/productSubscriptions/SiteAdminProductSubscriptionsPage.tsx */
    ProductSubscriptionsDotCom: (variables: ProductSubscriptionsDotComVariables) => ProductSubscriptionsDotComResult

    /** web/src/enterprise/site-admin/productSubscription/ProductSubscriptionStatus.tsx */
    ProductLicenseInfo: (variables: ProductLicenseInfoVariables) => ProductLicenseInfoResult

    /** web/src/enterprise/user/productSubscriptions/NewProductSubscriptionPaymentSection.tsx */
    PreviewProductSubscriptionInvoice: (
        variables: PreviewProductSubscriptionInvoiceVariables
    ) => PreviewProductSubscriptionInvoiceResult

    /** web/src/enterprise/user/productSubscriptions/UserSubscriptionsEditProductSubscriptionPage.tsx */
    ProductSubscriptionOnEditPage: (
        variables: ProductSubscriptionOnEditPageVariables
    ) => ProductSubscriptionOnEditPageResult

    /** web/src/enterprise/user/productSubscriptions/UserSubscriptionsEditProductSubscriptionPage.tsx */
    UpdatePaidProductSubscription: (
        variables: UpdatePaidProductSubscriptionVariables
    ) => UpdatePaidProductSubscriptionResult

    /** web/src/enterprise/user/productSubscriptions/UserSubscriptionsNewProductSubscriptionPage.tsx */
    CreatePaidProductSubscription: (
        variables: CreatePaidProductSubscriptionVariables
    ) => CreatePaidProductSubscriptionResult

    /** web/src/enterprise/user/productSubscriptions/UserSubscriptionsProductSubscriptionPage.tsx */
    ProductSubscription: (variables: ProductSubscriptionVariables) => ProductSubscriptionResult

    /** web/src/enterprise/user/productSubscriptions/UserSubscriptionsProductSubscriptionsPage.tsx */
    ProductSubscriptions: (variables: ProductSubscriptionsVariables) => ProductSubscriptionsResult

    /** web/src/enterprise/user/settings/ExternalAccountNode.tsx */
    DeleteExternalAccount: (variables: DeleteExternalAccountVariables) => DeleteExternalAccountResult

    /** web/src/enterprise/user/settings/UserEventLogsPage.tsx */
    UserEventLogs: (variables: UserEventLogsVariables) => UserEventLogsResult

    /** web/src/enterprise/user/settings/UserSettingsExternalAccountsPage.tsx */
    UserExternalAccounts: (variables: UserExternalAccountsVariables) => UserExternalAccountsResult

    /** web/src/enterprise/user/settings/auth/backend.ts */
    ScheduleUserPermissionsSync: (variables: ScheduleUserPermissionsSyncVariables) => ScheduleUserPermissionsSyncResult

    /** web/src/enterprise/user/settings/auth/backend.ts */
    UserPermissionsInfo: (variables: UserPermissionsInfoVariables) => UserPermissionsInfoResult

    /** web/src/extensions/ExtensionRegistry.tsx */
    RegistryExtensions: (variables: RegistryExtensionsVariables) => RegistryExtensionsResult

    /** web/src/extensions/extension/ExtensionArea.tsx */
    RegistryExtension: (variables: RegistryExtensionVariables) => RegistryExtensionResult

    /** web/src/insights/core/backend/requests/fetch-backend-insights.ts */
    Insights: (variables: InsightsVariables) => InsightsResult

    /** web/src/insights/core/backend/requests/fetch-backend-insights.ts */
    SubjectSettings: (variables: SubjectSettingsVariables) => SubjectSettingsResult

    /** web/src/insights/core/backend/requests/fetch-lang-stats-insight.ts */
    LangStatsInsightContent: (variables: LangStatsInsightContentVariables) => LangStatsInsightContentResult

    /** web/src/marketing/backend.ts */
    SubmitSurvey: (variables: SubmitSurveyVariables) => SubmitSurveyResult

    /** web/src/marketing/backend.ts */
    FetchSurveyResponses: (variables: FetchSurveyResponsesVariables) => FetchSurveyResponsesResult

    /** web/src/marketing/backend.ts */
    FetchAllUsersWithSurveyResponses: (
        variables: FetchAllUsersWithSurveyResponsesVariables
    ) => FetchAllUsersWithSurveyResponsesResult

    /** web/src/marketing/backend.ts */
    FetchSurveyResponseAggregates: (
        variables: FetchSurveyResponseAggregatesVariables
    ) => FetchSurveyResponseAggregatesResult

    /** web/src/marketing/backend.ts */
    RequestTrial: (variables: RequestTrialVariables) => RequestTrialResult

    /** web/src/nav/Feedback/FeedbackPrompt.tsx */
    SubmitHappinessFeedback: (variables: SubmitHappinessFeedbackVariables) => SubmitHappinessFeedbackResult

    /** web/src/nav/StatusMessagesNavItem.tsx */
    StatusMessages: (variables: StatusMessagesVariables) => StatusMessagesResult

    /** web/src/org/area/OrgArea.tsx */
    Organization: (variables: OrganizationVariables) => OrganizationResult

    /** web/src/org/area/OrgInvitationPage.tsx */
    RespondToOrganizationInvitation: (
        variables: RespondToOrganizationInvitationVariables
    ) => RespondToOrganizationInvitationResult

    /** web/src/org/backend.ts */
    CreateOrganization: (variables: CreateOrganizationVariables) => CreateOrganizationResult

    /** web/src/org/backend.ts */
    RemoveUserFromOrganization: (variables: RemoveUserFromOrganizationVariables) => RemoveUserFromOrganizationResult

    /** web/src/org/backend.ts */
    UpdateOrganization: (variables: UpdateOrganizationVariables) => UpdateOrganizationResult

    /** web/src/org/settings/members/InviteForm.tsx */
    InviteUserToOrganization: (variables: InviteUserToOrganizationVariables) => InviteUserToOrganizationResult

    /** web/src/org/settings/members/InviteForm.tsx */
    AddUserToOrganization: (variables: AddUserToOrganizationVariables) => AddUserToOrganizationResult

    /** web/src/org/settings/members/OrgSettingsMembersPage.tsx */
    OrganizationMembers: (variables: OrganizationMembersVariables) => OrganizationMembersResult

    /** web/src/platform/context.ts */
    ViewerSettings: (variables: ViewerSettingsVariables) => ViewerSettingsResult

    /** web/src/repo/GitReference.tsx */
    RepositoryGitRefs: (variables: RepositoryGitRefsVariables) => RepositoryGitRefsResult

    /** web/src/repo/RepoRevisionSidebarCommits.tsx */
    FetchCommits: (variables: FetchCommitsVariables) => FetchCommitsResult

    /** web/src/repo/RepoRevisionSidebarSymbols.tsx */
    Symbols: (variables: SymbolsVariables) => SymbolsResult

    /** web/src/repo/RepositoriesPopover.tsx */
    RepositoriesForPopover: (variables: RepositoriesForPopoverVariables) => RepositoriesForPopoverResult

    /** web/src/repo/RevisionsPopover.tsx */
    RepositoryGitCommit: (variables: RepositoryGitCommitVariables) => RepositoryGitCommitResult

    /** web/src/repo/backend.ts */
    RepositoryRedirect: (variables: RepositoryRedirectVariables) => RepositoryRedirectResult

    /** web/src/repo/backend.ts */
    ResolveRev: (variables: ResolveRevVariables) => ResolveRevResult

    /** web/src/repo/backend.ts */
    HighlightedFile: (variables: HighlightedFileVariables) => HighlightedFileResult

    /** web/src/repo/backend.ts */
    FileExternalLinks: (variables: FileExternalLinksVariables) => FileExternalLinksResult

    /** web/src/repo/backend.ts */
    TreeEntries: (variables: TreeEntriesVariables) => TreeEntriesResult

    /** web/src/repo/blob/BlobPage.tsx */
    Blob: (variables: BlobVariables) => BlobResult

    /** web/src/repo/branches/RepositoryBranchesOverviewPage.tsx */
    RepositoryGitBranchesOverview: (
        variables: RepositoryGitBranchesOverviewVariables
    ) => RepositoryGitBranchesOverviewResult

    /** web/src/repo/commit/RepositoryCommitPage.tsx */
    RepositoryCommit: (variables: RepositoryCommitVariables) => RepositoryCommitResult

    /** web/src/repo/commits/RepositoryCommitsPage.tsx */
    RepositoryGitCommits: (variables: RepositoryGitCommitsVariables) => RepositoryGitCommitsResult

    /** web/src/repo/compare/RepositoryCompareCommitsPage.tsx */
    RepositoryComparisonCommits: (variables: RepositoryComparisonCommitsVariables) => RepositoryComparisonCommitsResult

    /** web/src/repo/compare/RepositoryCompareDiffPage.tsx */
    RepositoryComparisonDiff: (variables: RepositoryComparisonDiffVariables) => RepositoryComparisonDiffResult

    /** web/src/repo/compare/RepositoryCompareOverviewPage.tsx */
    RepositoryComparison: (variables: RepositoryComparisonVariables) => RepositoryComparisonResult

    /** web/src/repo/settings/RepoSettingsIndexPage.tsx */
    RepositoryTextSearchIndex: (variables: RepositoryTextSearchIndexVariables) => RepositoryTextSearchIndexResult

    /** web/src/repo/settings/backend.ts */
    SettingsAreaRepository: (variables: SettingsAreaRepositoryVariables) => SettingsAreaRepositoryResult

    /** web/src/repo/stats/RepositoryStatsContributorsPage.tsx */
    RepositoryContributors: (variables: RepositoryContributorsVariables) => RepositoryContributorsResult

    /** web/src/repo/tree/TreePage.tsx */
    TreeCommits: (variables: TreeCommitsVariables) => TreeCommitsResult

    /** web/src/search/backend.tsx */
    Search: (variables: SearchVariables) => SearchResult

    /** web/src/search/backend.tsx */
    RepoGroups: (variables: RepoGroupsVariables) => RepoGroupsResult

    /** web/src/search/backend.tsx */
    ConvertVersionContextToSearchContext: (
        variables: ConvertVersionContextToSearchContextVariables
    ) => ConvertVersionContextToSearchContextResult

    /** web/src/search/backend.tsx */
    AutoDefinedSearchContexts: (variables: AutoDefinedSearchContextsVariables) => AutoDefinedSearchContextsResult

    /** web/src/search/backend.tsx */
    ListSearchContexts: (variables: ListSearchContextsVariables) => ListSearchContextsResult

    /** web/src/search/backend.tsx */
    FetchSearchContext: (variables: FetchSearchContextVariables) => FetchSearchContextResult

    /** web/src/search/backend.tsx */
    CreateSearchContext: (variables: CreateSearchContextVariables) => CreateSearchContextResult

    /** web/src/search/backend.tsx */
    UpdateSearchContext: (variables: UpdateSearchContextVariables) => UpdateSearchContextResult

    /** web/src/search/backend.tsx */
    IsSearchContextAvailable: (variables: IsSearchContextAvailableVariables) => IsSearchContextAvailableResult

    /** web/src/search/backend.tsx */
    SearchSuggestions: (variables: SearchSuggestionsVariables) => SearchSuggestionsResult

    /** web/src/search/backend.tsx */
    ReposByQuery: (variables: ReposByQueryVariables) => ReposByQueryResult

    /** web/src/search/backend.tsx */
    savedSearches: (variables: savedSearchesVariables) => savedSearchesResult

    /** web/src/search/backend.tsx */
    SavedSearch: (variables: SavedSearchVariables) => SavedSearchResult

    /** web/src/search/backend.tsx */
    CreateSavedSearch: (variables: CreateSavedSearchVariables) => CreateSavedSearchResult

    /** web/src/search/backend.tsx */
    UpdateSavedSearch: (variables: UpdateSavedSearchVariables) => UpdateSavedSearchResult

    /** web/src/search/backend.tsx */
    DeleteSavedSearch: (variables: DeleteSavedSearchVariables) => DeleteSavedSearchResult

    /** web/src/search/backend.tsx */
    highlightCode: (variables: highlightCodeVariables) => highlightCodeResult

    /** web/src/search/backend.tsx */
    ManyReposWarning: (variables: ManyReposWarningVariables) => ManyReposWarningResult

    /** web/src/search/backend.tsx */
    EventLogsData: (variables: EventLogsDataVariables) => EventLogsDataResult

    /** web/src/settings/SettingsArea.tsx */
    SettingsCascade: (variables: SettingsCascadeVariables) => SettingsCascadeResult

    /** web/src/settings/tokens/AccessTokenNode.tsx */
    DeleteAccessToken: (variables: DeleteAccessTokenVariables) => DeleteAccessTokenResult

    /** web/src/site-admin/SiteAdminTokensPage.tsx */
    SiteAdminAccessTokens: (variables: SiteAdminAccessTokensVariables) => SiteAdminAccessTokensResult

    /** web/src/site-admin/backend.ts */
    Users: (variables: UsersVariables) => UsersResult

    /** web/src/site-admin/backend.ts */
    Organizations: (variables: OrganizationsVariables) => OrganizationsResult

    /** web/src/site-admin/backend.ts */
    UserRepositories: (variables: UserRepositoriesVariables) => UserRepositoriesResult

    /** web/src/site-admin/backend.ts */
    Repositories: (variables: RepositoriesVariables) => RepositoriesResult

    /** web/src/site-admin/backend.ts */
    UpdateMirrorRepository: (variables: UpdateMirrorRepositoryVariables) => UpdateMirrorRepositoryResult

    /** web/src/site-admin/backend.ts */
    CheckMirrorRepositoryConnection: (
        variables: CheckMirrorRepositoryConnectionVariables
    ) => CheckMirrorRepositoryConnectionResult

    /** web/src/site-admin/backend.ts */
    ScheduleRepositoryPermissionsSync: (
        variables: ScheduleRepositoryPermissionsSyncVariables
    ) => ScheduleRepositoryPermissionsSyncResult

    /** web/src/site-admin/backend.ts */
    UserUsageStatistics: (variables: UserUsageStatisticsVariables) => UserUsageStatisticsResult

    /** web/src/site-admin/backend.ts */
    SiteUsageStatistics: (variables: SiteUsageStatisticsVariables) => SiteUsageStatisticsResult

    /** web/src/site-admin/backend.ts */
    Site: (variables: SiteVariables) => SiteResult

    /** web/src/site-admin/backend.ts */
    AllConfig: (variables: AllConfigVariables) => AllConfigResult

    /** web/src/site-admin/backend.ts */
    UpdateSiteConfiguration: (variables: UpdateSiteConfigurationVariables) => UpdateSiteConfigurationResult

    /** web/src/site-admin/backend.ts */
    ReloadSite: (variables: ReloadSiteVariables) => ReloadSiteResult

    /** web/src/site-admin/backend.ts */
    SetUserIsSiteAdmin: (variables: SetUserIsSiteAdminVariables) => SetUserIsSiteAdminResult

    /** web/src/site-admin/backend.ts */
    InvalidateSessionsByID: (variables: InvalidateSessionsByIDVariables) => InvalidateSessionsByIDResult

    /** web/src/site-admin/backend.ts */
    RandomizeUserPassword: (variables: RandomizeUserPasswordVariables) => RandomizeUserPasswordResult

    /** web/src/site-admin/backend.ts */
    DeleteUser: (variables: DeleteUserVariables) => DeleteUserResult

    /** web/src/site-admin/backend.ts */
    CreateUser: (variables: CreateUserVariables) => CreateUserResult

    /** web/src/site-admin/backend.ts */
    DeleteOrganization: (variables: DeleteOrganizationVariables) => DeleteOrganizationResult

    /** web/src/site-admin/backend.ts */
    SiteUpdateCheck: (variables: SiteUpdateCheckVariables) => SiteUpdateCheckResult

    /** web/src/site-admin/backend.ts */
    SiteMonitoringStatistics: (variables: SiteMonitoringStatisticsVariables) => SiteMonitoringStatisticsResult

    /** web/src/site-admin/backend.ts */
    UserPublicRepositories: (variables: UserPublicRepositoriesVariables) => UserPublicRepositoriesResult

    /** web/src/site-admin/backend.ts */
    SetUserPublicRepositories: (variables: SetUserPublicRepositoriesVariables) => SetUserPublicRepositoriesResult

    /** web/src/site-admin/backend.ts */
    OutOfBandMigrations: (variables: OutOfBandMigrationsVariables) => OutOfBandMigrationsResult

    /** web/src/site-admin/overview/SiteAdminOverviewPage.tsx */
    Overview: (variables: OverviewVariables) => OverviewResult

    /** web/src/site-admin/overview/SiteAdminOverviewPage.tsx */
    WAUs: (variables: WAUsVariables) => WAUsResult

    /** web/src/site/backend.tsx */
    SiteFlags: (variables: SiteFlagsVariables) => SiteFlagsResult

    /** web/src/tracking/withActivation.tsx */
    SiteAdminActivationStatus: (variables: SiteAdminActivationStatusVariables) => SiteAdminActivationStatusResult

    /** web/src/tracking/withActivation.tsx */
    ActivationStatus: (variables: ActivationStatusVariables) => ActivationStatusResult

    /** web/src/tracking/withActivation.tsx */
    LinksForRepositories: (variables: LinksForRepositoriesVariables) => LinksForRepositoriesResult

    /** web/src/user/area/UserArea.tsx */
    UserArea: (variables: UserAreaVariables) => UserAreaResult

    /** web/src/user/settings/accessTokens/UserSettingsCreateAccessTokenPage.tsx */
    CreateAccessToken: (variables: CreateAccessTokenVariables) => CreateAccessTokenResult

    /** web/src/user/settings/accessTokens/UserSettingsTokensPage.tsx */
    AccessTokens: (variables: AccessTokensVariables) => AccessTokensResult

    /** web/src/user/settings/auth/UserSettingsSecurityPage.tsx */
    MinExternalAccounts: (variables: MinExternalAccountsVariables) => MinExternalAccountsResult

    /** web/src/user/settings/backend.tsx */
    UpdatePassword: (variables: UpdatePasswordVariables) => UpdatePasswordResult

    /** web/src/user/settings/backend.tsx */
    CreatePassword: (variables: CreatePasswordVariables) => CreatePasswordResult

    /** web/src/user/settings/backend.tsx */
    SetUserEmailVerified: (variables: SetUserEmailVerifiedVariables) => SetUserEmailVerifiedResult

    /** web/src/user/settings/backend.tsx */
    LogUserEvent: (variables: LogUserEventVariables) => LogUserEventResult

    /** web/src/user/settings/backend.tsx */
    LogEvent: (variables: LogEventVariables) => LogEventResult

    /** web/src/user/settings/emails/AddUserEmailForm.tsx */
    AddUserEmail: (variables: AddUserEmailVariables) => AddUserEmailResult

    /** web/src/user/settings/emails/SetUserPrimaryEmailForm.tsx */
    SetUserEmailPrimary: (variables: SetUserEmailPrimaryVariables) => SetUserEmailPrimaryResult

    /** web/src/user/settings/emails/UserEmail.tsx */
    RemoveUserEmail: (variables: RemoveUserEmailVariables) => RemoveUserEmailResult

    /** web/src/user/settings/emails/UserEmail.tsx */
    ResendVerificationEmail: (variables: ResendVerificationEmailVariables) => ResendVerificationEmailResult

    /** web/src/user/settings/emails/UserSettingsEmailsPage.tsx */
    UserEmails: (variables: UserEmailsVariables) => UserEmailsResult

    /** web/src/user/settings/profile/EditUserProfileForm.tsx */
    UpdateUser: (variables: UpdateUserVariables) => UpdateUserResult
}

/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    /** A triple that represents all possible states of the published value: true, false or 'draft'. */
    PublishedValue: boolean | 'draft'
    /** A valid JSON value. */
    JSONValue: unknown
    /** A string that contains valid JSON, with additional support for //-style comments and trailing commas. */
    JSONCString: string
    /** A Git object ID (SHA-1 hash, 40 hexadecimal characters). */
    GitObjectID: string
    /** An arbitrarily large integer encoded as a decimal string. */
    BigInt: string
    /**
     * An RFC 3339-encoded UTC date string, such as 1973-11-29T21:33:09Z. This value can be parsed into a
     * JavaScript Date using Date.parse. To produce this value from a JavaScript Date instance, use
     * Date#toISOString.
     */
    DateTime: string
}

/** A user (identified either by username or email address) with its repository permission. */
export interface UserPermission {
    /**
     * Depending on the bindID option in the permissions.userMapping site configuration property,
     * the elements of the list are either all usernames (bindID of "username") or all email
     * addresses (bindID of "email").
     */
    bindID: Scalars['String']
    /** The highest level of repository permission. */
    permission?: Maybe<RepositoryPermission>
}

export { RepositoryPermission }

export { CampaignState }

export { ChangesetPublicationState }

export { ChangesetReconcilerState }

export { ChangesetExternalState }

export { ChangesetReviewState }

export { ChangesetCheckState }

export { ChangesetState }

export { ChangesetSpecOperation }

export { ChangesetSpecType }

export { BatchChangeState }

export { BulkOperationType }

export { BulkOperationState }

export { MonitorEmailPriority }

export { EventStatus }

/** The input required to create a code monitor. */
export interface MonitorInput {
    /**
     * The namespace represents the owner of the code monitor.
     * Owners can either be users or organizations.
     */
    namespace: Scalars['ID']
    /** A meaningful description of the code monitor. */
    description: Scalars['String']
    /** Whether the code monitor is enabled or not. */
    enabled: Scalars['Boolean']
}

/** The input required to edit a code monitor. */
export interface MonitorEditInput {
    /** The id of the monitor. */
    id: Scalars['ID']
    /** The desired state after the udpate. */
    update: MonitorInput
}

/** The input required to create a trigger. */
export interface MonitorTriggerInput {
    /** The query string. */
    query: Scalars['String']
}

/** The input required to edit a trigger. */
export interface MonitorEditTriggerInput {
    /** The id of the Trigger. */
    id: Scalars['ID']
    /** The desired state after the udpate. */
    update: MonitorTriggerInput
}

/** The input required to create an action. */
export interface MonitorActionInput {
    /** An email action. */
    email?: Maybe<MonitorEmailInput>
}

/** The input required to create an email action. */
export interface MonitorEmailInput {
    /** Whether the email action is enabled or not. */
    enabled: Scalars['Boolean']
    /** The priority of the email. */
    priority: MonitorEmailPriority
    /** A list of users or orgs which will receive the email. */
    recipients: Array<Scalars['ID']>
    /** Use header to automatically approve the message in a read-only or moderated mailing list. */
    header: Scalars['String']
}

/** The input required to edit an action. */
export interface MonitorEditActionInput {
    /** An email action. */
    email?: Maybe<MonitorEditEmailInput>
}

/** The input required to edit an email action. */
export interface MonitorEditEmailInput {
    /** The id of an email action. */
    id?: Maybe<Scalars['ID']>
    /** The desired state after the update. */
    update: MonitorEmailInput
}

export { LSIFUploadState }

export { LSIFIndexState }

/**
 * An input type that describes a product license to be generated and signed.
 * FOR INTERNAL USE ONLY.
 */
export interface ProductLicenseInput {
    /** The tags that indicate which features are activated by this license. */
    tags: Array<Scalars['String']>
    /** The number of users for which this product subscription is valid. */
    userCount: Scalars['Int']
    /** The expiration date of this product license, expressed as the number of seconds since the epoch. */
    expiresAt: Scalars['Int']
}

/**
 * An input type that describes a product subscription to be purchased. Corresponds to
 * ProductSubscriptionInvoiceItem.
 * FOR INTERNAL USE ONLY.
 */
export interface ProductSubscriptionInput {
    /**
     * The billing plan ID for the subscription (ProductPlan.billingPlanID). This also specifies the
     * billing product, because a plan is associated with its product in the billing system.
     */
    billingPlanID: Scalars['String']
    /** This subscription's user count. */
    userCount: Scalars['Int']
}

/** A new external service. */
export interface AddExternalServiceInput {
    /** The kind of the external service. */
    kind: ExternalServiceKind
    /** The display name of the external service. */
    displayName: Scalars['String']
    /** The JSON configuration of the external service. */
    config: Scalars['String']
    /**
     * The namespace this external service belongs to.
     * Currently, this can only be used for a user.
     */
    namespace?: Maybe<Scalars['ID']>
}

/** Fields to update for an existing external service. */
export interface UpdateExternalServiceInput {
    /** The id of the external service to update. */
    id: Scalars['ID']
    /** The updated display name, if provided. */
    displayName?: Maybe<Scalars['String']>
    /** The updated config, if provided. */
    config?: Maybe<Scalars['String']>
}

/** Describes options for rendering Markdown. */
export interface MarkdownOptions {
    /** A dummy null value (empty input types are not allowed yet). */
    alwaysNil?: Maybe<Scalars['String']>
}

export { EventSource }

/**
 * Input for Mutation.settingsMutation, which contains fields that all settings (global, organization, and user
 * settings) mutations need.
 */
export interface SettingsMutationGroupInput {
    /** The subject whose settings to mutate (organization, user, etc.). */
    subject: Scalars['ID']
    /**
     * The ID of the last-known settings known to the client, or null if there is none. This field is used to
     * prevent race conditions when there are concurrent editors.
     */
    lastID?: Maybe<Scalars['Int']>
}

/** An edit to a JSON property in a settings JSON object. The JSON property to edit can be nested. */
export interface SettingsEdit {
    /**
     * The key path of the property to update.
     *
     * Inserting into an existing array is not yet supported.
     */
    keyPath: Array<KeyPathSegment>
    /**
     * The new JSON-encoded value to insert. If the field's value is not set, the property is removed. (This is
     * different from the field's value being the JSON null value.)
     *
     * When the value is a non-primitive type, it must be specified using a GraphQL variable, not an inline literal,
     * or else the GraphQL parser will return an error.
     */
    value?: Maybe<Scalars['JSONValue']>
    /**
     * Whether to treat the value as a JSONC-encoded string, which makes it possible to perform an edit that
     * preserves (or adds/removes) comments.
     */
    valueIsJSONCEncodedString?: Maybe<Scalars['Boolean']>
}

/**
 * DEPRECATED: This type was renamed to SettingsEdit.
 * NOTE: GraphQL does not support @deprecated directives on INPUT_FIELD_DEFINITION (input fields).
 */
export interface ConfigurationEdit {
    /** DEPRECATED */
    keyPath: Array<KeyPathSegment>
    /** DEPRECATED */
    value?: Maybe<Scalars['JSONValue']>
    /** DEPRECATED */
    valueIsJSONCEncodedString?: Maybe<Scalars['Boolean']>
}

/**
 * A segment of a key path that locates a nested JSON value in a root JSON value. Exactly one field in each
 * KeyPathSegment must be non-null.
 * For example, in {"a": [0, {"b": 3}]}, the value 3 is located at the key path ["a", 1, "b"].
 */
export interface KeyPathSegment {
    /** The name of the property in the object at this location to descend into. */
    property?: Maybe<Scalars['String']>
    /** The index of the array at this location to descend into. */
    index?: Maybe<Scalars['Int']>
}

/** Input for a user satisfaction (NPS) survey submission. */
export interface SurveySubmissionInput {
    /**
     * User-provided email address, if there is no currently authenticated user. If there is, this value
     * will not be used.
     */
    email?: Maybe<Scalars['String']>
    /** User's likelihood of recommending Sourcegraph to a friend, from 0-10. */
    score: Scalars['Int']
    /** The answer to "What is the most important reason for the score you gave". */
    reason?: Maybe<Scalars['String']>
    /** The answer to "What can Sourcegraph do to provide a better product" */
    better?: Maybe<Scalars['String']>
}

/** Input for a happiness feedback submission. */
export interface HappinessFeedbackSubmissionInput {
    /** User's happiness rating, from 1-4. */
    score: Scalars['Int']
    /** The answer to "What's going well? What could be better?". */
    feedback?: Maybe<Scalars['String']>
    /** The path that the happiness feedback will be submitted from. */
    currentPath?: Maybe<Scalars['String']>
}

export { SearchVersion }

export { SearchPatternType }

export { ExternalServiceKind }

export { SearchContextsNamespaceFilterType }

export { SearchContextsOrderBy }

/** Input for a new search context. */
export interface SearchContextInput {
    /**
     * Search context name. Not the same as the search context spec. Search context namespace and search context name
     * are used to construct the fully-qualified search context spec.
     * Example mappings from search context spec to search context name: global -> global, @user -> user, @org -> org,
     * @user/ctx1 -> ctx1, @org/ctxs/ctx -> ctxs/ctx.
     */
    name: Scalars['String']
    /** Search context description. */
    description: Scalars['String']
    /**
     * Public property controls the visibility of the search context. Public search context is available to
     * any user on the instance. If a public search context contains private repositories, those are filtered out
     * for unauthorized users. Private search contexts are only available to their owners. Private user search context
     * is available only to the user, private org search context is available only to the members of the org, and private
     * instance-level search contexts are available only to site-admins.
     */
    public: Scalars['Boolean']
    /** Namespace of the search context (user or org). If not set, search context is considered instance-level. */
    namespace?: Maybe<Scalars['ID']>
}

/** Input for editing an existing search context. */
export interface SearchContextEditInput {
    /**
     * Search context name. Not the same as the search context spec. Search context namespace and search context name
     * are used to construct the fully-qualified search context spec.
     * Example mappings from search context spec to search context name: global -> global, @user -> user, @org -> org,
     * @user/ctx1 -> ctx1, @org/ctxs/ctx -> ctxs/ctx.
     */
    name: Scalars['String']
    /** Search context description. */
    description: Scalars['String']
    /**
     * Public property controls the visibility of the search context. Public search context is available to
     * any user on the instance. If a public search context contains private repositories, those are filtered out
     * for unauthorized users. Private search contexts are only available to their owners. Private user search context
     * is available only to the user, private org search context is available only to the members of the org, and private
     * instance-level search contexts are available only to site-admins.
     */
    public: Scalars['Boolean']
}

/** Input for a set of revisions to be searched within a repository. */
export interface SearchContextRepositoryRevisionsInput {
    /** ID of the repository to be searched. */
    repositoryID: Scalars['ID']
    /** Revisions in the repository to be searched. */
    revisions: Array<Scalars['String']>
}

export { DiffHunkLineType }

/** A specific highlighted line range to fetch. */
export interface HighlightLineRange {
    /**
     * The first line to fetch (0-indexed, inclusive). Values outside the bounds of the file will
     * automatically be clamped within the valid range.
     */
    startLine: Scalars['Int']
    /**
     * The last line to fetch (0-indexed, inclusive). Values outside the bounds of the file will
     * automatically be clamped within the valid range.
     */
    endLine: Scalars['Int']
}

export { DiagnosticSeverity }

export { SymbolKind }

export { GitRefType }

export { GitRefOrder }

export { GitObjectType }

export { OrganizationInvitationResponseType }

export { RepositoryOrderBy }

export { AlertType }

export { UserEvent }

export { UserActivePeriod }

export type CurrentAuthStateVariables = Exact<{ [key: string]: never }>

export type CurrentAuthStateResult = {
    currentUser: Maybe<{
        __typename: 'User'
        id: string
        databaseID: number
        username: string
        avatarURL: Maybe<string>
        email: string
        displayName: Maybe<string>
        siteAdmin: boolean
        tags: Array<string>
        url: string
        settingsURL: Maybe<string>
        viewerCanAdminister: boolean
        organizations: {
            nodes: Array<{
                id: string
                name: string
                displayName: Maybe<string>
                url: string
                settingsURL: Maybe<string>
            }>
        }
        session: { canSignOut: boolean }
    }>
}

export type FileDiffHunkRangeFields = { startLine: number; lines: number }

export type DiffStatFields = { added: number; changed: number; deleted: number }

export type FileDiffHunkFields = {
    oldNoNewlineAt: boolean
    section: Maybe<string>
    oldRange: { startLine: number; lines: number }
    newRange: { startLine: number; lines: number }
    highlight: { aborted: boolean; lines: Array<{ kind: DiffHunkLineType; html: string }> }
}

export type FileDiffFields = {
    oldPath: Maybe<string>
    newPath: Maybe<string>
    internalID: string
    oldFile: Maybe<
        | { __typename: 'GitBlob'; binary: boolean; byteSize: number }
        | { __typename: 'VirtualFile'; binary: boolean; byteSize: number }
    >
    newFile: Maybe<
        | { __typename: 'GitBlob'; binary: boolean; byteSize: number }
        | { __typename: 'VirtualFile'; binary: boolean; byteSize: number }
    >
    mostRelevantFile: { __typename: 'GitBlob'; url: string } | { __typename: 'VirtualFile'; url: string }
    hunks: Array<FileDiffHunkFields>
    stat: { added: number; changed: number; deleted: number }
}

export type ExternalServiceFields = {
    id: string
    kind: ExternalServiceKind
    displayName: string
    config: string
    warning: Maybe<string>
    lastSyncError: Maybe<string>
    repoCount: number
    webhookURL: Maybe<string>
    lastSyncAt: Maybe<string>
    nextSyncAt: Maybe<string>
    updatedAt: string
    createdAt: string
    namespace: Maybe<
        { id: string; namespaceName: string; url: string } | { id: string; namespaceName: string; url: string }
    >
}

export type AddExternalServiceVariables = Exact<{
    input: AddExternalServiceInput
}>

export type AddExternalServiceResult = { addExternalService: ExternalServiceFields }

export type UpdateExternalServiceVariables = Exact<{
    input: UpdateExternalServiceInput
}>

export type UpdateExternalServiceResult = { updateExternalService: ExternalServiceFields }

export type SetExternalServiceReposVariables = Exact<{
    id: Scalars['ID']
    allRepos: Scalars['Boolean']
    repos: Maybe<Array<Scalars['String']>>
}>

export type SetExternalServiceReposResult = { setExternalServiceRepos: { alwaysNil: Maybe<string> } }

export type ExternalServiceVariables = Exact<{
    id: Scalars['ID']
}>

export type ExternalServiceResult = {
    node: Maybe<
        | { __typename: 'Repository' }
        | { __typename: 'User' }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | ({ __typename: 'ExternalService' } & ExternalServiceFields)
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type AffiliatedRepositoriesVariables = Exact<{
    user: Scalars['ID']
    codeHost: Maybe<Scalars['ID']>
    query: Maybe<Scalars['String']>
}>

export type AffiliatedRepositoriesResult = {
    affiliatedRepositories: {
        nodes: Array<{
            name: string
            private: boolean
            codeHost: Maybe<{ kind: ExternalServiceKind; id: string; displayName: string }>
        }>
    }
}

export type DeleteExternalServiceVariables = Exact<{
    externalService: Scalars['ID']
}>

export type DeleteExternalServiceResult = { deleteExternalService: { alwaysNil: Maybe<string> } }

export type ListExternalServiceFields = {
    id: string
    kind: ExternalServiceKind
    displayName: string
    config: string
    warning: Maybe<string>
    lastSyncError: Maybe<string>
    repoCount: number
    lastSyncAt: Maybe<string>
    nextSyncAt: Maybe<string>
    updatedAt: string
    createdAt: string
    namespace: Maybe<
        { id: string; namespaceName: string; url: string } | { id: string; namespaceName: string; url: string }
    >
}

export type ExternalServicesVariables = Exact<{
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
    namespace: Maybe<Scalars['ID']>
}>

export type ExternalServicesResult = {
    externalServices: {
        totalCount: number
        nodes: Array<ListExternalServiceFields>
        pageInfo: { endCursor: Maybe<string>; hasNextPage: boolean }
    }
}

export type CloseBatchChangeVariables = Exact<{
    batchChange: Scalars['ID']
    closeChangesets: Maybe<Scalars['Boolean']>
}>

export type CloseBatchChangeResult = { closeBatchChange: { id: string } }

export type ChangesetsStatsFields = {
    total: number
    closed: number
    deleted: number
    draft: number
    merged: number
    open: number
    unpublished: number
    archived: number
}

export type BulkOperationFields = {
    id: string
    type: BulkOperationType
    state: BulkOperationState
    progress: number
    changesetCount: number
    createdAt: string
    finishedAt: Maybe<string>
    errors: Array<{
        error: Maybe<string>
        changeset:
            | { __typename: 'HiddenExternalChangeset' }
            | {
                  __typename: 'ExternalChangeset'
                  title: Maybe<string>
                  externalURL: Maybe<{ url: string }>
                  repository: { name: string; url: string }
              }
    }>
    initiator: { username: string; url: string }
}

export type BatchChangeFields = {
    __typename: 'BatchChange'
    id: string
    url: string
    name: string
    description: Maybe<string>
    createdAt: string
    lastAppliedAt: string
    updatedAt: string
    closedAt: Maybe<string>
    viewerCanAdminister: boolean
    namespace: { namespaceName: string; url: string } | { namespaceName: string; url: string }
    initialApplier: Maybe<{ username: string; url: string }>
    lastApplier: Maybe<{ username: string; url: string }>
    diffStat: DiffStatFields
    changesetsStats: ChangesetsStatsFields
    bulkOperations: { totalCount: number }
    activeBulkOperations: ActiveBulkOperationsConnectionFields
    currentSpec: { originalInput: string; supersedingBatchSpec: Maybe<{ createdAt: string; applyURL: string }> }
}

export type ActiveBulkOperationsConnectionFields = { totalCount: number; nodes: Array<ActiveBulkOperationFields> }

export type ActiveBulkOperationFields = { id: string; state: BulkOperationState }

export type ChangesetLabelFields = { color: string; description: Maybe<string>; text: string }

export type BatchChangeByNamespaceVariables = Exact<{
    namespaceID: Scalars['ID']
    batchChange: Scalars['String']
    createdAfter: Scalars['DateTime']
}>

export type BatchChangeByNamespaceResult = { batchChange: Maybe<BatchChangeFields> }

export type HiddenExternalChangesetFields = {
    __typename: 'HiddenExternalChangeset'
    id: string
    createdAt: string
    updatedAt: string
    nextSyncAt: Maybe<string>
    state: ChangesetState
}

export type ExternalChangesetFields = {
    __typename: 'ExternalChangeset'
    id: string
    title: Maybe<string>
    body: Maybe<string>
    state: ChangesetState
    reviewState: Maybe<ChangesetReviewState>
    checkState: Maybe<ChangesetCheckState>
    error: Maybe<string>
    syncerError: Maybe<string>
    externalID: Maybe<string>
    createdAt: string
    updatedAt: string
    nextSyncAt: Maybe<string>
    labels: Array<ChangesetLabelFields>
    repository: { id: string; name: string; url: string }
    externalURL: Maybe<{ url: string }>
    diffStat: Maybe<DiffStatFields>
    currentSpec: Maybe<{
        id: string
        type: ChangesetSpecType
        description:
            | { __typename: 'ExistingChangesetReference' }
            | { __typename: 'GitBranchChangesetDescription'; headRef: string }
    }>
}

type ChangesetFields_HiddenExternalChangeset_ = {
    __typename: 'HiddenExternalChangeset'
} & HiddenExternalChangesetFields

type ChangesetFields_ExternalChangeset_ = { __typename: 'ExternalChangeset' } & ExternalChangesetFields

export type ChangesetFields = ChangesetFields_HiddenExternalChangeset_ | ChangesetFields_ExternalChangeset_

export type BatchChangeChangesetsVariables = Exact<{
    batchChange: Scalars['ID']
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
    state: Maybe<ChangesetState>
    reviewState: Maybe<ChangesetReviewState>
    checkState: Maybe<ChangesetCheckState>
    onlyPublishedByThisBatchChange: Maybe<Scalars['Boolean']>
    search: Maybe<Scalars['String']>
    onlyArchived: Maybe<Scalars['Boolean']>
}>

export type BatchChangeChangesetsResult = {
    node: Maybe<
        | { __typename: 'Repository' }
        | { __typename: 'User' }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | {
              __typename: 'BatchChange'
              changesets: {
                  totalCount: number
                  pageInfo: { endCursor: Maybe<string>; hasNextPage: boolean }
                  nodes: Array<ChangesetFields_HiddenExternalChangeset_ | ChangesetFields_ExternalChangeset_>
              }
          }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type SyncChangesetVariables = Exact<{
    changeset: Scalars['ID']
}>

export type SyncChangesetResult = { syncChangeset: { alwaysNil: Maybe<string> } }

export type ReenqueueChangesetVariables = Exact<{
    changeset: Scalars['ID']
}>

export type ReenqueueChangesetResult = {
    reenqueueChangeset: ChangesetFields_HiddenExternalChangeset_ | ChangesetFields_ExternalChangeset_
}

type GitRefSpecFields_GitRef_ = { __typename: 'GitRef'; target: { oid: string } }

type GitRefSpecFields_GitRevSpecExpr_ = { __typename: 'GitRevSpecExpr'; object: Maybe<{ oid: string }> }

type GitRefSpecFields_GitObject_ = { __typename: 'GitObject'; oid: string }

export type GitRefSpecFields = GitRefSpecFields_GitRef_ | GitRefSpecFields_GitRevSpecExpr_ | GitRefSpecFields_GitObject_

export type ExternalChangesetFileDiffsFields = {
    diff: Maybe<
        | {
              __typename: 'RepositoryComparison'
              range: {
                  base: GitRefSpecFields_GitRef_ | GitRefSpecFields_GitRevSpecExpr_ | GitRefSpecFields_GitObject_
                  head: GitRefSpecFields_GitRef_ | GitRefSpecFields_GitRevSpecExpr_ | GitRefSpecFields_GitObject_
              }
              fileDiffs: {
                  totalCount: Maybe<number>
                  nodes: Array<FileDiffFields>
                  pageInfo: { hasNextPage: boolean; endCursor: Maybe<string> }
              }
          }
        | {
              __typename: 'PreviewRepositoryComparison'
              fileDiffs: {
                  totalCount: Maybe<number>
                  nodes: Array<FileDiffFields>
                  pageInfo: { hasNextPage: boolean; endCursor: Maybe<string> }
              }
          }
    >
}

export type ExternalChangesetFileDiffsVariables = Exact<{
    externalChangeset: Scalars['ID']
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
    isLightTheme: Scalars['Boolean']
}>

export type ExternalChangesetFileDiffsResult = {
    node: Maybe<
        | { __typename: 'Repository' }
        | { __typename: 'User' }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | ({ __typename: 'ExternalChangeset' } & ExternalChangesetFileDiffsFields)
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type ChangesetCountsOverTimeFields = {
    date: string
    merged: number
    closed: number
    draft: number
    openApproved: number
    openChangesRequested: number
    openPending: number
    total: number
}

export type ChangesetCountsOverTimeVariables = Exact<{
    batchChange: Scalars['ID']
    includeArchived: Scalars['Boolean']
}>

export type ChangesetCountsOverTimeResult = {
    node: Maybe<
        | { __typename: 'Repository' }
        | { __typename: 'User' }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange'; changesetCountsOverTime: Array<ChangesetCountsOverTimeFields> }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type DeleteBatchChangeVariables = Exact<{
    batchChange: Scalars['ID']
}>

export type DeleteBatchChangeResult = { deleteBatchChange: Maybe<{ alwaysNil: Maybe<string> }> }

export type ChangesetDiffFields = { currentSpec: Maybe<{ description: { commits: Array<{ diff: string }> } }> }

export type ChangesetDiffVariables = Exact<{
    changeset: Scalars['ID']
}>

export type ChangesetDiffResult = {
    node: Maybe<
        | { __typename: 'Repository' }
        | { __typename: 'User' }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | ({ __typename: 'ExternalChangeset' } & ChangesetDiffFields)
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type ChangesetScheduleEstimateFields = { scheduleEstimateAt: Maybe<string> }

export type ChangesetScheduleEstimateVariables = Exact<{
    changeset: Scalars['ID']
}>

export type ChangesetScheduleEstimateResult = {
    node: Maybe<
        | { __typename: 'Repository' }
        | { __typename: 'User' }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | ({ __typename: 'ExternalChangeset' } & ChangesetScheduleEstimateFields)
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type DetachChangesetsVariables = Exact<{
    batchChange: Scalars['ID']
    changesets: Array<Scalars['ID']>
}>

export type DetachChangesetsResult = { detachChangesets: { id: string } }

export type CreateChangesetCommentsVariables = Exact<{
    batchChange: Scalars['ID']
    changesets: Array<Scalars['ID']>
    body: Scalars['String']
}>

export type CreateChangesetCommentsResult = { createChangesetComments: { id: string } }

export type BatchChangeBulkOperationsVariables = Exact<{
    batchChange: Scalars['ID']
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
}>

export type BatchChangeBulkOperationsResult = {
    node: Maybe<
        | { __typename: 'Repository' }
        | { __typename: 'User' }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange'; bulkOperations: BulkOperationConnectionFields }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type BulkOperationConnectionFields = {
    totalCount: number
    pageInfo: { hasNextPage: boolean; endCursor: Maybe<string> }
    nodes: Array<BulkOperationFields>
}

export type AllChangesetIDsVariables = Exact<{
    batchChange: Scalars['ID']
    after: Maybe<Scalars['String']>
    state: Maybe<ChangesetState>
    reviewState: Maybe<ChangesetReviewState>
    checkState: Maybe<ChangesetCheckState>
    onlyPublishedByThisBatchChange: Maybe<Scalars['Boolean']>
    search: Maybe<Scalars['String']>
    onlyArchived: Maybe<Scalars['Boolean']>
}>

export type AllChangesetIDsResult = {
    node: Maybe<
        | { __typename: 'Repository' }
        | { __typename: 'User' }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange'; changesets: ChangesetIDConnectionFields }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type ChangesetIDConnectionFields = {
    nodes: Array<
        { __typename: 'HiddenExternalChangeset'; id: string } | { __typename: 'ExternalChangeset'; id: string }
    >
    pageInfo: { hasNextPage: boolean; endCursor: Maybe<string> }
}

export type ListBatchChange = {
    id: string
    url: string
    name: string
    description: Maybe<string>
    createdAt: string
    closedAt: Maybe<string>
    namespace: { namespaceName: string; url: string } | { namespaceName: string; url: string }
    changesetsStats: { open: number; closed: number; merged: number }
}

export type BatchChangesVariables = Exact<{
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
    state: Maybe<BatchChangeState>
    viewerCanAdminister: Maybe<Scalars['Boolean']>
}>

export type BatchChangesResult = {
    batchChanges: {
        totalCount: number
        nodes: Array<ListBatchChange>
        pageInfo: { endCursor: Maybe<string>; hasNextPage: boolean }
    }
    allBatchChanges: { totalCount: number }
}

export type BatchChangesByNamespaceVariables = Exact<{
    namespaceID: Scalars['ID']
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
    state: Maybe<BatchChangeState>
    viewerCanAdminister: Maybe<Scalars['Boolean']>
}>

export type BatchChangesByNamespaceResult = {
    node: Maybe<
        | { __typename: 'Repository' }
        | { __typename: 'User'; batchChanges: BatchChangesFields; allBatchChanges: { totalCount: number } }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org'; batchChanges: BatchChangesFields; allBatchChanges: { totalCount: number } }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type BatchChangesFields = {
    totalCount: number
    nodes: Array<ListBatchChange>
    pageInfo: { endCursor: Maybe<string>; hasNextPage: boolean }
}

export type AreBatchChangesLicensedVariables = Exact<{ [key: string]: never }>

export type AreBatchChangesLicensedResult = { campaigns: boolean; batchChanges: boolean }

export type ViewerBatchChangesCodeHostsFields = {
    totalCount: number
    nodes: Array<{ externalServiceURL: string; externalServiceKind: ExternalServiceKind }>
}

export type SupersedingBatchSpecFields = { createdAt: string; applyURL: string }

export type BatchSpecFields = {
    id: string
    createdAt: string
    expiresAt: Maybe<string>
    viewerCanAdminister: boolean
    description: { name: string; description: string }
    appliesToBatchChange: Maybe<{ id: string; name: string; url: string }>
    creator: Maybe<{ username: string; url: string }>
    namespace: { namespaceName: string; url: string } | { namespaceName: string; url: string }
    diffStat: DiffStatFields
    applyPreview: {
        stats: {
            close: number
            detach: number
            archive: number
            import: number
            publish: number
            publishDraft: number
            push: number
            reopen: number
            undraft: number
            update: number
            added: number
            modified: number
            removed: number
        }
    }
    supersedingBatchSpec: Maybe<SupersedingBatchSpecFields>
    viewerBatchChangesCodeHosts: ViewerBatchChangesCodeHostsFields
}

export type BatchSpecByIDVariables = Exact<{
    batchSpec: Scalars['ID']
}>

export type BatchSpecByIDResult = {
    node: Maybe<
        | { __typename: 'Repository' }
        | { __typename: 'User' }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | ({ __typename: 'BatchSpec' } & BatchSpecFields)
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type CreateBatchChangeVariables = Exact<{
    batchSpec: Scalars['ID']
}>

export type CreateBatchChangeResult = { createBatchChange: { id: string; url: string } }

export type ApplyBatchChangeVariables = Exact<{
    batchSpec: Scalars['ID']
    batchChange: Scalars['ID']
}>

export type ApplyBatchChangeResult = { applyBatchChange: { id: string; url: string } }

type CommonChangesetSpecFields_HiddenChangesetSpec_ = { type: ChangesetSpecType }

type CommonChangesetSpecFields_VisibleChangesetSpec_ = { type: ChangesetSpecType }

export type CommonChangesetSpecFields =
    | CommonChangesetSpecFields_HiddenChangesetSpec_
    | CommonChangesetSpecFields_VisibleChangesetSpec_

export type HiddenChangesetSpecFields = {
    __typename: 'HiddenChangesetSpec'
    id: string
} & CommonChangesetSpecFields_HiddenChangesetSpec_

export type VisibleChangesetSpecFields = {
    __typename: 'VisibleChangesetSpec'
    id: string
    description:
        | ({ __typename: 'ExistingChangesetReference' } & ExistingChangesetReferenceFields)
        | ({ __typename: 'GitBranchChangesetDescription' } & GitBranchChangesetDescriptionFields)
} & CommonChangesetSpecFields_VisibleChangesetSpec_

export type ExistingChangesetReferenceFields = { externalID: string; baseRepository: { name: string; url: string } }

export type GitBranchChangesetDescriptionFields = {
    title: string
    published: boolean | 'draft'
    body: string
    baseRef: string
    headRef: string
    baseRepository: { name: string; url: string }
    commits: Array<{
        subject: string
        body: Maybe<string>
        author: {
            avatarURL: Maybe<string>
            email: string
            displayName: string
            user: Maybe<{ username: string; displayName: Maybe<string>; url: string }>
        }
    }>
    diffStat: DiffStatFields
}

export type BatchSpecApplyPreviewConnectionFields = {
    totalCount: number
    pageInfo: { endCursor: Maybe<string>; hasNextPage: boolean }
    nodes: Array<
        | ChangesetApplyPreviewFields_VisibleChangesetApplyPreview_
        | ChangesetApplyPreviewFields_HiddenChangesetApplyPreview_
    >
}

type ChangesetApplyPreviewFields_VisibleChangesetApplyPreview_ = {
    __typename: 'VisibleChangesetApplyPreview'
} & VisibleChangesetApplyPreviewFields

type ChangesetApplyPreviewFields_HiddenChangesetApplyPreview_ = {
    __typename: 'HiddenChangesetApplyPreview'
} & HiddenChangesetApplyPreviewFields

export type ChangesetApplyPreviewFields =
    | ChangesetApplyPreviewFields_VisibleChangesetApplyPreview_
    | ChangesetApplyPreviewFields_HiddenChangesetApplyPreview_

export type HiddenChangesetApplyPreviewFields = {
    __typename: 'HiddenChangesetApplyPreview'
    targets:
        | { __typename: 'HiddenApplyPreviewTargetsAttach'; changesetSpec: HiddenChangesetSpecFields }
        | {
              __typename: 'HiddenApplyPreviewTargetsUpdate'
              changesetSpec: HiddenChangesetSpecFields
              changeset: { id: string; state: ChangesetState }
          }
        | { __typename: 'HiddenApplyPreviewTargetsDetach'; changeset: { id: string; state: ChangesetState } }
}

export type VisibleChangesetApplyPreviewFields = {
    __typename: 'VisibleChangesetApplyPreview'
    operations: Array<ChangesetSpecOperation>
    delta: {
        titleChanged: boolean
        bodyChanged: boolean
        baseRefChanged: boolean
        diffChanged: boolean
        authorEmailChanged: boolean
        authorNameChanged: boolean
        commitMessageChanged: boolean
    }
    targets:
        | { __typename: 'VisibleApplyPreviewTargetsAttach'; changesetSpec: VisibleChangesetSpecFields }
        | {
              __typename: 'VisibleApplyPreviewTargetsUpdate'
              changesetSpec: VisibleChangesetSpecFields
              changeset: {
                  id: string
                  title: Maybe<string>
                  state: ChangesetState
                  externalID: Maybe<string>
                  externalURL: Maybe<{ url: string }>
                  currentSpec: Maybe<{
                      description:
                          | { __typename: 'ExistingChangesetReference' }
                          | {
                                __typename: 'GitBranchChangesetDescription'
                                baseRef: string
                                title: string
                                body: string
                                commits: Array<{
                                    body: Maybe<string>
                                    subject: string
                                    author: {
                                        avatarURL: Maybe<string>
                                        email: string
                                        displayName: string
                                        user: Maybe<{ username: string; displayName: Maybe<string>; url: string }>
                                    }
                                }>
                            }
                  }>
                  author: Maybe<PersonLinkFields>
              }
          }
        | {
              __typename: 'VisibleApplyPreviewTargetsDetach'
              changeset: {
                  id: string
                  title: Maybe<string>
                  state: ChangesetState
                  externalID: Maybe<string>
                  externalURL: Maybe<{ url: string }>
                  repository: { url: string; name: string }
                  diffStat: Maybe<{ added: number; changed: number; deleted: number }>
              }
          }
}

export type BatchSpecApplyPreviewVariables = Exact<{
    batchSpec: Scalars['ID']
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
    search: Maybe<Scalars['String']>
    currentState: Maybe<ChangesetState>
    action: Maybe<ChangesetSpecOperation>
}>

export type BatchSpecApplyPreviewResult = {
    node: Maybe<
        | { __typename: 'Repository' }
        | { __typename: 'User' }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec'; applyPreview: BatchSpecApplyPreviewConnectionFields }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type ChangesetSpecFileDiffsFields = {
    description:
        | { __typename: 'ExistingChangesetReference' }
        | { __typename: 'GitBranchChangesetDescription'; diff: { fileDiffs: ChangesetSpecFileDiffConnectionFields } }
}

export type ChangesetSpecFileDiffConnectionFields = {
    totalCount: Maybe<number>
    nodes: Array<FileDiffFields>
    pageInfo: { hasNextPage: boolean; endCursor: Maybe<string> }
}

export type ChangesetSpecFileDiffsVariables = Exact<{
    changesetSpec: Scalars['ID']
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
    isLightTheme: Scalars['Boolean']
}>

export type ChangesetSpecFileDiffsResult = {
    node: Maybe<
        | { __typename: 'Repository' }
        | { __typename: 'User' }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | ({ __typename: 'VisibleChangesetSpec' } & ChangesetSpecFileDiffsFields)
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type BatchChangesCredentialFields = { id: string; sshPublicKey: Maybe<string>; isSiteCredential: boolean }

export type CreateBatchChangesCredentialVariables = Exact<{
    user: Maybe<Scalars['ID']>
    credential: Scalars['String']
    externalServiceKind: ExternalServiceKind
    externalServiceURL: Scalars['String']
}>

export type CreateBatchChangesCredentialResult = { createBatchChangesCredential: BatchChangesCredentialFields }

export type DeleteBatchChangesCredentialVariables = Exact<{
    id: Scalars['ID']
}>

export type DeleteBatchChangesCredentialResult = { deleteBatchChangesCredential: { alwaysNil: Maybe<string> } }

export type BatchChangesCodeHostsFields = {
    totalCount: number
    pageInfo: { hasNextPage: boolean; endCursor: Maybe<string> }
    nodes: Array<BatchChangesCodeHostFields>
}

export type BatchChangesCodeHostFields = {
    externalServiceKind: ExternalServiceKind
    externalServiceURL: string
    requiresSSH: boolean
    credential: Maybe<BatchChangesCredentialFields>
}

export type UserBatchChangesCodeHostsVariables = Exact<{
    user: Scalars['ID']
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
}>

export type UserBatchChangesCodeHostsResult = {
    node: Maybe<
        | { __typename: 'Repository' }
        | { __typename: 'User'; batchChangesCodeHosts: BatchChangesCodeHostsFields }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type GlobalBatchChangesCodeHostsVariables = Exact<{
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
}>

export type GlobalBatchChangesCodeHostsResult = { batchChangesCodeHosts: BatchChangesCodeHostsFields }

export type CodeMonitorFields = {
    id: string
    description: string
    enabled: boolean
    trigger: { id: string; query: string }
    actions: {
        nodes: Array<{ id: string; enabled: boolean; recipients: { nodes: Array<{ id: string } | { id: string }> } }>
    }
}

export type ListCodeMonitors = {
    totalCount: number
    nodes: Array<CodeMonitorFields>
    pageInfo: { endCursor: Maybe<string>; hasNextPage: boolean }
}

export type CreateCodeMonitorVariables = Exact<{
    monitor: MonitorInput
    trigger: MonitorTriggerInput
    actions: Array<MonitorActionInput>
}>

export type CreateCodeMonitorResult = { createCodeMonitor: { description: string } }

export type ListUserCodeMonitorsVariables = Exact<{
    id: Scalars['ID']
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
}>

export type ListUserCodeMonitorsResult = {
    node: Maybe<
        | { __typename: 'Repository' }
        | { __typename: 'User'; monitors: ListCodeMonitors }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type ToggleCodeMonitorEnabledVariables = Exact<{
    id: Scalars['ID']
    enabled: Scalars['Boolean']
}>

export type ToggleCodeMonitorEnabledResult = { toggleCodeMonitor: { id: string; enabled: boolean } }

export type FetchCodeMonitorVariables = Exact<{
    id: Scalars['ID']
}>

export type FetchCodeMonitorResult = {
    node: Maybe<{
        id: string
        description: string
        enabled: boolean
        owner: { id: string; namespaceName: string } | { id: string; namespaceName: string }
        actions: {
            nodes: Array<{
                id: string
                enabled: boolean
                recipients: { nodes: Array<{ id: string; url: string } | { id: string; url: string }> }
            }>
        }
        trigger: { id: string; query: string }
    }>
}

export type UpdateCodeMonitorVariables = Exact<{
    monitor: MonitorEditInput
    trigger: MonitorEditTriggerInput
    actions: Array<MonitorEditActionInput>
}>

export type UpdateCodeMonitorResult = { updateCodeMonitor: CodeMonitorFields }

export type DeleteCodeMonitorVariables = Exact<{
    id: Scalars['ID']
}>

export type DeleteCodeMonitorResult = { deleteCodeMonitor: { alwaysNil: Maybe<string> } }

export type ResetTriggerQueryTimestampsVariables = Exact<{
    id: Scalars['ID']
}>

export type ResetTriggerQueryTimestampsResult = { resetTriggerQueryTimestamps: { alwaysNil: Maybe<string> } }

export type TriggerTestEmailActionVariables = Exact<{
    namespace: Scalars['ID']
    description: Scalars['String']
    email: MonitorEmailInput
}>

export type TriggerTestEmailActionResult = { triggerTestEmailAction: { alwaysNil: Maybe<string> } }

export type IndexConfigurationVariables = Exact<{
    id: Scalars['ID']
}>

export type IndexConfigurationResult = { node: Maybe<RepositoryIndexConfigurationFields> }

export type RepositoryIndexConfigurationFields = {
    __typename: 'Repository'
    indexConfiguration: Maybe<{ configuration: Maybe<string> }>
}

export type UpdateRepositoryIndexConfigurationVariables = Exact<{
    id: Scalars['ID']
    content: Scalars['String']
}>

export type UpdateRepositoryIndexConfigurationResult = {
    updateRepositoryIndexConfiguration: Maybe<{ alwaysNil: Maybe<string> }>
}

export type QueueAutoIndexJobForRepoVariables = Exact<{
    id: Scalars['ID']
}>

export type QueueAutoIndexJobForRepoResult = { queueAutoIndexJobForRepo: Maybe<{ alwaysNil: Maybe<string> }> }

export type LsifUploadVariables = Exact<{
    id: Scalars['ID']
}>

export type LsifUploadResult = { node: Maybe<LsifUploadFields> }

export type LsifIndexVariables = Exact<{
    id: Scalars['ID']
}>

export type LsifIndexResult = { node: Maybe<LsifIndexFields> }

export type DeleteLsifUploadVariables = Exact<{
    id: Scalars['ID']
}>

export type DeleteLsifUploadResult = { deleteLSIFUpload: Maybe<{ alwaysNil: Maybe<string> }> }

export type DeleteLsifIndexVariables = Exact<{
    id: Scalars['ID']
}>

export type DeleteLsifIndexResult = { deleteLSIFIndex: Maybe<{ alwaysNil: Maybe<string> }> }

export type LsifUploadsForRepoVariables = Exact<{
    repository: Scalars['ID']
    state: Maybe<LSIFUploadState>
    isLatestForRepo: Maybe<Scalars['Boolean']>
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
    query: Maybe<Scalars['String']>
}>

export type LsifUploadsForRepoResult = {
    node: Maybe<
        | { __typename: 'Repository'; lsifUploads: LsifUploadConnectionFields }
        | { __typename: 'User' }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type LsifUploadConnectionFields = {
    totalCount: Maybe<number>
    nodes: Array<LsifUploadFields>
    pageInfo: { endCursor: Maybe<string>; hasNextPage: boolean }
}

export type LsifUploadsVariables = Exact<{
    state: Maybe<LSIFUploadState>
    isLatestForRepo: Maybe<Scalars['Boolean']>
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
    query: Maybe<Scalars['String']>
}>

export type LsifUploadsResult = {
    lsifUploads: {
        totalCount: Maybe<number>
        nodes: Array<LsifUploadFields>
        pageInfo: { endCursor: Maybe<string>; hasNextPage: boolean }
    }
}

export type CodeIntelligenceCommitGraphMetadataVariables = Exact<{
    repository: Scalars['ID']
}>

export type CodeIntelligenceCommitGraphMetadataResult = {
    node: Maybe<
        | { __typename: 'Repository'; codeIntelligenceCommitGraph: { stale: boolean; updatedAt: Maybe<string> } }
        | { __typename: 'User' }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type LsifIndexesForRepoVariables = Exact<{
    repository: Scalars['ID']
    state: Maybe<LSIFIndexState>
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
    query: Maybe<Scalars['String']>
}>

export type LsifIndexesForRepoResult = {
    node: Maybe<
        | {
              __typename: 'Repository'
              lsifIndexes: {
                  totalCount: Maybe<number>
                  nodes: Array<LsifIndexFields>
                  pageInfo: { endCursor: Maybe<string>; hasNextPage: boolean }
              }
          }
        | { __typename: 'User' }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type LsifIndexesVariables = Exact<{
    state: Maybe<LSIFIndexState>
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
    query: Maybe<Scalars['String']>
}>

export type LsifIndexesResult = {
    lsifIndexes: {
        totalCount: Maybe<number>
        nodes: Array<LsifIndexFields>
        pageInfo: { endCursor: Maybe<string>; hasNextPage: boolean }
    }
}

export type LsifUploadFields = {
    __typename: 'LSIFUpload'
    id: string
    inputCommit: string
    inputRoot: string
    inputIndexer: string
    state: LSIFUploadState
    failure: Maybe<string>
    isLatestForRepo: boolean
    uploadedAt: string
    startedAt: Maybe<string>
    finishedAt: Maybe<string>
    placeInQueue: Maybe<number>
    projectRoot: Maybe<{
        url: string
        path: string
        repository: { url: string; name: string }
        commit: { url: string; oid: string; abbreviatedOID: string }
    }>
}

export type LsifIndexFields = {
    __typename: 'LSIFIndex'
    id: string
    inputCommit: string
    inputRoot: string
    inputIndexer: string
    state: LSIFIndexState
    failure: Maybe<string>
    queuedAt: string
    startedAt: Maybe<string>
    finishedAt: Maybe<string>
    placeInQueue: Maybe<number>
    projectRoot: Maybe<{
        url: string
        path: string
        repository: { url: string; name: string }
        commit: { url: string; oid: string; abbreviatedOID: string }
    }>
    steps: LsifIndexStepsFields
}

export type LsifIndexStepsFields = {
    setup: Array<ExecutionLogEntryFields>
    preIndex: Array<{ root: string; image: string; commands: Array<string>; logEntry: Maybe<ExecutionLogEntryFields> }>
    index: { indexerArgs: Array<string>; outfile: Maybe<string>; logEntry: Maybe<ExecutionLogEntryFields> }
    upload: Maybe<ExecutionLogEntryFields>
    teardown: Array<ExecutionLogEntryFields>
}

export type ExecutionLogEntryFields = {
    key: string
    command: Array<string>
    startTime: string
    exitCode: number
    out: string
    durationMilliseconds: number
}

export type ProductPlansVariables = Exact<{ [key: string]: never }>

export type ProductPlansResult = {
    dotcom: {
        productPlans: Array<{
            productPlanID: string
            billingPlanID: string
            name: string
            pricePerUserPerYear: number
            minQuantity: Maybe<number>
            maxQuantity: Maybe<number>
            tiersMode: string
            planTiers: Array<{ unitAmount: number; upTo: number; flatAmount: number }>
        }>
    }
}

export type ProductSubscriptionFields = {
    id: string
    name: string
    createdAt: string
    isArchived: boolean
    url: string
    account: Maybe<{
        id: string
        username: string
        displayName: Maybe<string>
        emails: Array<{ email: string; verified: boolean }>
    }>
    invoiceItem: Maybe<{ userCount: number; expiresAt: string; plan: { nameWithBrand: string } }>
    activeLicense: Maybe<{
        licenseKey: string
        info: Maybe<{ productNameWithBrand: string; tags: Array<string>; userCount: number; expiresAt: string }>
    }>
}

export type UpdateRegistryExtensionVariables = Exact<{
    extension: Scalars['ID']
    name: Maybe<Scalars['String']>
}>

export type UpdateRegistryExtensionResult = { extensionRegistry: { updateExtension: { extension: { url: string } } } }

export type PublishRegistryExtensionVariables = Exact<{
    extensionID: Scalars['String']
    manifest: Scalars['String']
    bundle: Scalars['String']
}>

export type PublishRegistryExtensionResult = { extensionRegistry: { publishExtension: { extension: { url: string } } } }

export type CreateRegistryExtensionVariables = Exact<{
    publisher: Scalars['ID']
    name: Scalars['String']
}>

export type CreateRegistryExtensionResult = {
    extensionRegistry: { createExtension: { extension: { id: string; extensionID: string; url: string } } }
}

export type DeleteRegistryExtensionVariables = Exact<{
    extension: Scalars['ID']
}>

export type DeleteRegistryExtensionResult = { extensionRegistry: { deleteExtension: { alwaysNil: Maybe<string> } } }

export type ViewerRegistryPublishersVariables = Exact<{ [key: string]: never }>

export type ViewerRegistryPublishersResult = {
    extensionRegistry: {
        localExtensionIDPrefix: Maybe<string>
        viewerPublishers: Array<
            { __typename: 'User'; id: string; username: string } | { __typename: 'Org'; id: string; name: string }
        >
    }
}

export type RepoPermissionsInfoVariables = Exact<{
    repo: Scalars['ID']
}>

export type RepoPermissionsInfoResult = {
    node: Maybe<
        | { __typename: 'Repository'; permissionsInfo: Maybe<RepoPermissionsInfoFields> }
        | { __typename: 'User' }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type RepoPermissionsInfoFields = { syncedAt: Maybe<string>; updatedAt: string }

export type SearchResultsStatsVariables = Exact<{
    query: Scalars['String']
}>

export type SearchResultsStatsResult = {
    search: Maybe<{ results: { limitHit: boolean }; stats: { languages: Array<{ name: string; totalLines: number }> } }>
}

export type AuthProviderFields = {
    serviceType: string
    serviceID: string
    clientID: string
    displayName: string
    isBuiltin: boolean
    authenticationURL: Maybe<string>
}

export type AuthProvidersVariables = Exact<{ [key: string]: never }>

export type AuthProvidersResult = {
    site: {
        authProviders: { totalCount: number; nodes: Array<AuthProviderFields>; pageInfo: { hasNextPage: boolean } }
    }
}

export type ExternalAccountsVariables = Exact<{
    first: Maybe<Scalars['Int']>
    user: Maybe<Scalars['ID']>
    serviceType: Maybe<Scalars['String']>
    serviceID: Maybe<Scalars['String']>
    clientID: Maybe<Scalars['String']>
}>

export type ExternalAccountsResult = { site: { externalAccounts: ExternalAccountsConnectionFields } }

export type SiteAdminRegistryExtensionsVariables = Exact<{
    first: Maybe<Scalars['Int']>
    publisher: Maybe<Scalars['ID']>
    query: Maybe<Scalars['String']>
    local: Maybe<Scalars['Boolean']>
    remote: Maybe<Scalars['Boolean']>
}>

export type SiteAdminRegistryExtensionsResult = {
    extensionRegistry: {
        extensions: {
            totalCount: number
            error: Maybe<string>
            nodes: Array<RegistryExtensionFields>
            pageInfo: { hasNextPage: boolean }
        }
    }
}

export type SiteAdminLsifUploadVariables = Exact<{
    id: Scalars['ID']
}>

export type SiteAdminLsifUploadResult = {
    node: Maybe<
        | { __typename: 'Repository' }
        | { __typename: 'User' }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload'; projectRoot: Maybe<{ commit: { repository: { name: string; url: string } } }> }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type SetCustomerBillingVariables = Exact<{
    user: Scalars['ID']
    billingCustomerID: Maybe<Scalars['String']>
}>

export type SetCustomerBillingResult = { dotcom: { setUserBilling: { alwaysNil: Maybe<string> } } }

export type CustomerFields = {
    id: string
    username: string
    displayName: Maybe<string>
    urlForSiteAdminBilling: Maybe<string>
}

export type CustomersVariables = Exact<{
    first: Maybe<Scalars['Int']>
    query: Maybe<Scalars['String']>
}>

export type CustomersResult = {
    users: { totalCount: number; nodes: Array<CustomerFields>; pageInfo: { hasNextPage: boolean } }
}

export type CreateProductSubscriptionVariables = Exact<{
    accountID: Scalars['ID']
}>

export type CreateProductSubscriptionResult = {
    dotcom: { createProductSubscription: { urlForSiteAdmin: Maybe<string> } }
}

export type ProductSubscriptionAccountsVariables = Exact<{
    first: Maybe<Scalars['Int']>
    query: Maybe<Scalars['String']>
}>

export type ProductSubscriptionAccountsResult = {
    users: {
        totalCount: number
        nodes: Array<{
            id: string
            username: string
            emails: Array<{ email: string; verified: boolean; isPrimary: boolean }>
        }>
        pageInfo: { hasNextPage: boolean }
    }
}

export type GenerateProductLicenseForSubscriptionVariables = Exact<{
    productSubscriptionID: Scalars['ID']
    license: ProductLicenseInput
}>

export type GenerateProductLicenseForSubscriptionResult = {
    dotcom: { generateProductLicenseForSubscription: { id: string } }
}

export type ProductLicenseFields = {
    id: string
    licenseKey: string
    createdAt: string
    subscription: {
        id: string
        name: string
        urlForSiteAdmin: Maybe<string>
        account: Maybe<{ id: string; username: string; displayName: Maybe<string> }>
        activeLicense: Maybe<{ id: string }>
    }
    info: Maybe<{ productNameWithBrand: string; tags: Array<string>; userCount: number; expiresAt: string }>
}

export type DotComProductLicensesVariables = Exact<{
    first: Maybe<Scalars['Int']>
    licenseKeySubstring: Maybe<Scalars['String']>
}>

export type DotComProductLicensesResult = {
    dotcom: {
        productLicenses: { totalCount: number; nodes: Array<ProductLicenseFields>; pageInfo: { hasNextPage: boolean } }
    }
}

export type SetProductSubscriptionBillingVariables = Exact<{
    id: Scalars['ID']
    billingSubscriptionID: Maybe<Scalars['String']>
}>

export type SetProductSubscriptionBillingResult = {
    dotcom: { setProductSubscriptionBilling: { alwaysNil: Maybe<string> } }
}

export type SiteAdminProductSubscriptionFields = {
    id: string
    name: string
    createdAt: string
    isArchived: boolean
    urlForSiteAdmin: Maybe<string>
    account: Maybe<{
        id: string
        username: string
        displayName: Maybe<string>
        emails: Array<{ email: string; isPrimary: boolean }>
    }>
    invoiceItem: Maybe<{ userCount: number; expiresAt: string; plan: { nameWithBrand: string } }>
    activeLicense: Maybe<{
        id: string
        licenseKey: string
        createdAt: string
        info: Maybe<{ productNameWithBrand: string; tags: Array<string>; userCount: number; expiresAt: string }>
    }>
}

export type DotComProductSubscriptionVariables = Exact<{
    uuid: Scalars['String']
}>

export type DotComProductSubscriptionResult = {
    dotcom: {
        productSubscription: {
            id: string
            name: string
            createdAt: string
            isArchived: boolean
            url: string
            urlForSiteAdminBilling: Maybe<string>
            account: Maybe<{
                id: string
                username: string
                displayName: Maybe<string>
                emails: Array<{ email: string; verified: boolean }>
            }>
            invoiceItem: Maybe<{
                userCount: number
                expiresAt: string
                plan: { billingPlanID: string; name: string; nameWithBrand: string; pricePerUserPerYear: number }
            }>
            events: Array<{ id: string; date: string; title: string; description: Maybe<string>; url: Maybe<string> }>
            productLicenses: {
                totalCount: number
                nodes: Array<{
                    id: string
                    licenseKey: string
                    createdAt: string
                    info: Maybe<{ tags: Array<string>; userCount: number; expiresAt: string }>
                }>
                pageInfo: { hasNextPage: boolean }
            }
        }
    }
}

export type ProductLicensesVariables = Exact<{
    first: Maybe<Scalars['Int']>
    subscriptionUUID: Scalars['String']
}>

export type ProductLicensesResult = {
    dotcom: {
        productSubscription: {
            productLicenses: {
                totalCount: number
                nodes: Array<ProductLicenseFields>
                pageInfo: { hasNextPage: boolean }
            }
        }
    }
}

export type ArchiveProductSubscriptionVariables = Exact<{
    id: Scalars['ID']
}>

export type ArchiveProductSubscriptionResult = { dotcom: { archiveProductSubscription: { alwaysNil: Maybe<string> } } }

export type ProductSubscriptionsDotComVariables = Exact<{
    first: Maybe<Scalars['Int']>
    account: Maybe<Scalars['ID']>
    query: Maybe<Scalars['String']>
}>

export type ProductSubscriptionsDotComResult = {
    dotcom: {
        productSubscriptions: {
            totalCount: number
            nodes: Array<SiteAdminProductSubscriptionFields>
            pageInfo: { hasNextPage: boolean }
        }
    }
}

export type ProductLicenseInfoVariables = Exact<{ [key: string]: never }>

export type ProductLicenseInfoResult = {
    site: {
        productSubscription: {
            productNameWithBrand: string
            actualUserCount: number
            actualUserCountDate: string
            noLicenseWarningUserCount: Maybe<number>
            license: Maybe<{ tags: Array<string>; userCount: number; expiresAt: string }>
        }
    }
    users: { totalCount: number }
}

export type PreviewProductSubscriptionInvoiceVariables = Exact<{
    account: Maybe<Scalars['ID']>
    subscriptionToUpdate: Maybe<Scalars['ID']>
    productSubscription: ProductSubscriptionInput
}>

export type PreviewProductSubscriptionInvoiceResult = {
    dotcom: {
        previewProductSubscriptionInvoice: {
            price: number
            prorationDate: Maybe<string>
            isDowngradeRequiringManualIntervention: boolean
            beforeInvoiceItem: Maybe<{
                userCount: number
                expiresAt: string
                plan: { billingPlanID: string; name: string; pricePerUserPerYear: number }
            }>
            afterInvoiceItem: {
                userCount: number
                expiresAt: string
                plan: { billingPlanID: string; name: string; pricePerUserPerYear: number }
            }
        }
    }
}

export type ProductSubscriptionOnEditPageVariables = Exact<{
    uuid: Scalars['String']
}>

export type ProductSubscriptionOnEditPageResult = {
    dotcom: { productSubscription: ProductSubscriptionFieldsOnEditPage }
}

export type ProductSubscriptionFieldsOnEditPage = {
    id: string
    name: string
    url: string
    invoiceItem: Maybe<{ userCount: number; expiresAt: string; plan: { billingPlanID: string } }>
}

export type UpdatePaidProductSubscriptionVariables = Exact<{
    subscriptionID: Scalars['ID']
    update: ProductSubscriptionInput
    paymentToken: Maybe<Scalars['String']>
}>

export type UpdatePaidProductSubscriptionResult = {
    dotcom: { updatePaidProductSubscription: { productSubscription: { url: string } } }
}

export type CreatePaidProductSubscriptionVariables = Exact<{
    accountID: Scalars['ID']
    productSubscription: ProductSubscriptionInput
    paymentToken: Maybe<Scalars['String']>
}>

export type CreatePaidProductSubscriptionResult = {
    dotcom: { createPaidProductSubscription: { productSubscription: { id: string; name: string; url: string } } }
}

export type ProductSubscriptionVariables = Exact<{
    uuid: Scalars['String']
}>

export type ProductSubscriptionResult = { dotcom: { productSubscription: ProductSubscriptionFieldsOnSubscriptionPage } }

export type ProductSubscriptionFieldsOnSubscriptionPage = {
    id: string
    name: string
    createdAt: string
    isArchived: boolean
    url: string
    urlForSiteAdmin: Maybe<string>
    account: Maybe<{
        id: string
        username: string
        displayName: Maybe<string>
        emails: Array<{ email: string; verified: boolean }>
    }>
    invoiceItem: Maybe<{
        userCount: number
        expiresAt: string
        plan: { billingPlanID: string; name: string; nameWithBrand: string; pricePerUserPerYear: number }
    }>
    events: Array<{ id: string; date: string; title: string; description: Maybe<string>; url: Maybe<string> }>
    activeLicense: Maybe<{
        licenseKey: string
        info: Maybe<{ productNameWithBrand: string; tags: Array<string>; userCount: number; expiresAt: string }>
    }>
}

export type ProductSubscriptionsVariables = Exact<{
    first: Maybe<Scalars['Int']>
    account: Maybe<Scalars['ID']>
}>

export type ProductSubscriptionsResult = {
    dotcom: {
        productSubscriptions: {
            totalCount: number
            nodes: Array<ProductSubscriptionFields>
            pageInfo: { hasNextPage: boolean }
        }
    }
}

export type ExternalAccountFields = {
    id: string
    serviceType: string
    serviceID: string
    clientID: string
    accountID: string
    createdAt: string
    updatedAt: string
    refreshURL: Maybe<string>
    accountData: Maybe<unknown>
    user: { id: string; username: string }
}

export type ExternalAccountsConnectionFields = {
    totalCount: number
    nodes: Array<ExternalAccountFields>
    pageInfo: { hasNextPage: boolean }
}

export type DeleteExternalAccountVariables = Exact<{
    externalAccount: Scalars['ID']
}>

export type DeleteExternalAccountResult = { deleteExternalAccount: { alwaysNil: Maybe<string> } }

export type UserEventLogsVariables = Exact<{
    user: Scalars['ID']
    first: Maybe<Scalars['Int']>
}>

export type UserEventLogsResult = {
    node: Maybe<
        | { __typename: 'Repository' }
        | { __typename: 'User'; eventLogs: UserEventLogsConnectionFields }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type UserEventLogsConnectionFields = {
    totalCount: number
    nodes: Array<UserEventLogFields>
    pageInfo: { hasNextPage: boolean }
}

export type UserEventLogFields = { name: string; source: EventSource; url: string; timestamp: string }

export type UserExternalAccountsVariables = Exact<{
    user: Scalars['ID']
    first: Maybe<Scalars['Int']>
}>

export type UserExternalAccountsResult = { node: Maybe<{ externalAccounts: ExternalAccountsConnectionFields }> }

export type ScheduleUserPermissionsSyncVariables = Exact<{
    user: Scalars['ID']
}>

export type ScheduleUserPermissionsSyncResult = { scheduleUserPermissionsSync: { alwaysNil: Maybe<string> } }

export type UserPermissionsInfoVariables = Exact<{
    user: Scalars['ID']
}>

export type UserPermissionsInfoResult = {
    node: Maybe<
        | { __typename: 'Repository' }
        | { __typename: 'User'; permissionsInfo: Maybe<UserPermissionsInfoFields> }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type UserPermissionsInfoFields = { syncedAt: Maybe<string>; updatedAt: string }

export type RegistryExtensionsVariables = Exact<{
    query: Maybe<Scalars['String']>
    prioritizeExtensionIDs: Array<Scalars['String']>
}>

export type RegistryExtensionsResult = {
    extensionRegistry: { extensions: { error: Maybe<string>; nodes: Array<RegistryExtensionFieldsForList> } }
}

export type RegistryExtensionFieldsForList = {
    id: string
    extensionID: string
    extensionIDWithoutRegistry: string
    name: string
    createdAt: Maybe<string>
    updatedAt: Maybe<string>
    url: string
    remoteURL: Maybe<string>
    registryName: string
    isLocal: boolean
    isWorkInProgress: boolean
    viewerCanAdminister: boolean
    publisher: Maybe<
        | { __typename: 'User'; id: string; username: string; displayName: Maybe<string>; url: string }
        | { __typename: 'Org'; id: string; name: string; displayName: Maybe<string>; url: string }
    >
    manifest: Maybe<{ raw: string; description: Maybe<string> }>
}

export type RegistryExtensionFields = {
    id: string
    extensionID: string
    extensionIDWithoutRegistry: string
    name: string
    createdAt: Maybe<string>
    updatedAt: Maybe<string>
    publishedAt: Maybe<string>
    url: string
    remoteURL: Maybe<string>
    registryName: string
    isLocal: boolean
    isWorkInProgress: boolean
    viewerCanAdminister: boolean
    publisher: Maybe<
        | { __typename: 'User'; id: string; username: string; displayName: Maybe<string>; url: string }
        | { __typename: 'Org'; id: string; name: string; displayName: Maybe<string>; url: string }
    >
    manifest: Maybe<{ raw: string; description: Maybe<string> }>
}

export type RegistryExtensionVariables = Exact<{
    extensionID: Scalars['String']
}>

export type RegistryExtensionResult = { extensionRegistry: { extension: Maybe<RegistryExtensionFields> } }

export type InsightFields = {
    title: string
    description: string
    series: Array<{ label: string; points: Array<{ dateTime: string; value: number }> }>
}

export type InsightsVariables = Exact<{ [key: string]: never }>

export type InsightsResult = { insights: Maybe<{ nodes: Array<InsightFields> }> }

export type SubjectSettingsVariables = Exact<{
    id: Scalars['ID']
}>

export type SubjectSettingsResult = {
    settingsSubject: Maybe<
        | { latestSettings: Maybe<{ id: number; contents: string }> }
        | { latestSettings: Maybe<{ id: number; contents: string }> }
        | { latestSettings: Maybe<{ id: number; contents: string }> }
        | { latestSettings: Maybe<{ id: number; contents: string }> }
    >
}

export type LangStatsInsightContentVariables = Exact<{
    query: Scalars['String']
}>

export type LangStatsInsightContentResult = {
    search: Maybe<{ results: { limitHit: boolean }; stats: { languages: Array<{ name: string; totalLines: number }> } }>
}

type BulkSearchRepositories_Repository_ = { name: string }

type BulkSearchRepositories_Redirect_ = { url: string }

export type BulkSearchRepositories = BulkSearchRepositories_Repository_ | BulkSearchRepositories_Redirect_

export type BulkSearchFields = { results: { matchCount: number } }

export type BulkSearchCommits = {
    results: { results: Array<{ commit: { oid: string; committer: Maybe<{ date: string }> } }> }
}

export type SubmitSurveyVariables = Exact<{
    input: SurveySubmissionInput
}>

export type SubmitSurveyResult = { submitSurvey: Maybe<{ alwaysNil: Maybe<string> }> }

export type FetchSurveyResponsesVariables = Exact<{ [key: string]: never }>

export type FetchSurveyResponsesResult = { surveyResponses: { totalCount: number; nodes: Array<SurveyResponseFields> } }

export type SurveyResponseFields = {
    email: Maybe<string>
    score: number
    reason: Maybe<string>
    better: Maybe<string>
    createdAt: string
    user: Maybe<{ id: string; username: string; emails: Array<{ email: string }> }>
}

export type FetchAllUsersWithSurveyResponsesVariables = Exact<{
    activePeriod: Maybe<UserActivePeriod>
    first: Maybe<Scalars['Int']>
    query: Maybe<Scalars['String']>
}>

export type FetchAllUsersWithSurveyResponsesResult = {
    users: { totalCount: number; nodes: Array<UserWithSurveyResponseFields> }
}

export type UserWithSurveyResponseFields = {
    id: string
    username: string
    emails: Array<{ email: string }>
    surveyResponses: Array<{ score: number; reason: Maybe<string>; better: Maybe<string>; createdAt: string }>
    usageStatistics: { lastActiveTime: Maybe<string> }
}

export type FetchSurveyResponseAggregatesVariables = Exact<{ [key: string]: never }>

export type FetchSurveyResponseAggregatesResult = { surveyResponses: SurveyResponseAggregateFields }

export type SurveyResponseAggregateFields = {
    totalCount: number
    last30DaysCount: number
    averageScore: number
    netPromoterScore: number
}

export type RequestTrialVariables = Exact<{
    email: Scalars['String']
}>

export type RequestTrialResult = { requestTrial: Maybe<{ alwaysNil: Maybe<string> }> }

export type SubmitHappinessFeedbackVariables = Exact<{
    input: HappinessFeedbackSubmissionInput
}>

export type SubmitHappinessFeedbackResult = { submitHappinessFeedback: Maybe<{ alwaysNil: Maybe<string> }> }

export type StatusMessagesVariables = Exact<{ [key: string]: never }>

export type StatusMessagesResult = {
    statusMessages: Array<
        | StatusMessageFields_CloningProgress_
        | StatusMessageFields_IndexingProgress_
        | StatusMessageFields_ExternalServiceSyncError_
        | StatusMessageFields_SyncError_
    >
}

type StatusMessageFields_CloningProgress_ = { __typename: 'CloningProgress'; message: string }

type StatusMessageFields_IndexingProgress_ = { __typename: 'IndexingProgress'; message: string }

type StatusMessageFields_ExternalServiceSyncError_ = {
    __typename: 'ExternalServiceSyncError'
    message: string
    externalService: { id: string; displayName: string }
}

type StatusMessageFields_SyncError_ = { __typename: 'SyncError'; message: string }

export type StatusMessageFields =
    | StatusMessageFields_CloningProgress_
    | StatusMessageFields_IndexingProgress_
    | StatusMessageFields_ExternalServiceSyncError_
    | StatusMessageFields_SyncError_

export type OrganizationVariables = Exact<{
    name: Scalars['String']
}>

export type OrganizationResult = { organization: Maybe<OrgAreaOrganizationFields> }

export type OrgAreaOrganizationFields = {
    __typename: 'Org'
    id: string
    name: string
    displayName: Maybe<string>
    url: string
    settingsURL: Maybe<string>
    viewerIsMember: boolean
    viewerCanAdminister: boolean
    createdAt: string
    viewerPendingInvitation: Maybe<{
        id: string
        respondURL: Maybe<string>
        sender: { username: string; displayName: Maybe<string>; avatarURL: Maybe<string>; createdAt: string }
    }>
}

export type RespondToOrganizationInvitationVariables = Exact<{
    organizationInvitation: Scalars['ID']
    responseType: OrganizationInvitationResponseType
}>

export type RespondToOrganizationInvitationResult = { respondToOrganizationInvitation: { alwaysNil: Maybe<string> } }

export type CreateOrganizationVariables = Exact<{
    name: Scalars['String']
    displayName: Maybe<Scalars['String']>
}>

export type CreateOrganizationResult = { createOrganization: { id: string; name: string; settingsURL: Maybe<string> } }

export type RemoveUserFromOrganizationVariables = Exact<{
    user: Scalars['ID']
    organization: Scalars['ID']
}>

export type RemoveUserFromOrganizationResult = { removeUserFromOrganization: Maybe<{ alwaysNil: Maybe<string> }> }

export type UpdateOrganizationVariables = Exact<{
    id: Scalars['ID']
    displayName: Maybe<Scalars['String']>
}>

export type UpdateOrganizationResult = { updateOrganization: { id: string } }

export type InviteUserToOrganizationVariables = Exact<{
    organization: Scalars['ID']
    username: Scalars['String']
}>

export type InviteUserToOrganizationResult = { inviteUserToOrganization: InviteUserToOrganizationFields }

export type InviteUserToOrganizationFields = { sentInvitationEmail: boolean; invitationURL: string }

export type AddUserToOrganizationVariables = Exact<{
    organization: Scalars['ID']
    username: Scalars['String']
}>

export type AddUserToOrganizationResult = { addUserToOrganization: { alwaysNil: Maybe<string> } }

export type OrganizationMembersVariables = Exact<{
    id: Scalars['ID']
}>

export type OrganizationMembersResult = {
    node: Maybe<{
        viewerCanAdminister: boolean
        members: {
            totalCount: number
            nodes: Array<{ id: string; username: string; displayName: Maybe<string>; avatarURL: Maybe<string> }>
        }
    }>
}

export type PersonLinkFields = {
    email: string
    displayName: string
    user: Maybe<{ username: string; displayName: Maybe<string>; url: string }>
}

export type SettingsCascadeFields = {
    final: string
    subjects: Array<
        | {
              __typename: 'User'
              id: string
              username: string
              displayName: Maybe<string>
              settingsURL: Maybe<string>
              viewerCanAdminister: boolean
              latestSettings: Maybe<{ id: number; contents: string }>
          }
        | {
              __typename: 'Org'
              id: string
              name: string
              displayName: Maybe<string>
              settingsURL: Maybe<string>
              viewerCanAdminister: boolean
              latestSettings: Maybe<{ id: number; contents: string }>
          }
        | {
              __typename: 'DefaultSettings'
              settingsURL: Maybe<string>
              viewerCanAdminister: boolean
              latestSettings: Maybe<{ id: number; contents: string }>
          }
        | {
              __typename: 'Site'
              id: string
              siteID: string
              settingsURL: Maybe<string>
              viewerCanAdminister: boolean
              latestSettings: Maybe<{ id: number; contents: string }>
          }
    >
}

export type ViewerSettingsVariables = Exact<{ [key: string]: never }>

export type ViewerSettingsResult = { viewerSettings: SettingsCascadeFields }

export type GitRefFields = {
    id: string
    displayName: string
    name: string
    abbrevName: string
    url: string
    target: {
        commit: Maybe<{
            author: SignatureFieldsForReferences
            committer: Maybe<SignatureFieldsForReferences>
            behindAhead: { behind: number; ahead: number }
        }>
    }
}

export type SignatureFieldsForReferences = {
    date: string
    person: { displayName: string; user: Maybe<{ username: string }> }
}

export type RepositoryGitRefsVariables = Exact<{
    repo: Scalars['ID']
    first: Maybe<Scalars['Int']>
    query: Maybe<Scalars['String']>
    type: GitRefType
    withBehindAhead: Scalars['Boolean']
}>

export type RepositoryGitRefsResult = { node: Maybe<{ gitRefs: GitRefConnectionFields }> }

export type GitRefConnectionFields = {
    totalCount: number
    nodes: Array<GitRefFields>
    pageInfo: { hasNextPage: boolean }
}

export type FetchCommitsVariables = Exact<{
    repo: Scalars['ID']
    revision: Scalars['String']
    first: Maybe<Scalars['Int']>
    currentPath: Maybe<Scalars['String']>
    query: Maybe<Scalars['String']>
}>

export type FetchCommitsResult = {
    node: Maybe<
        | { __typename: 'Repository'; commit: Maybe<{ ancestors: CommitAncestorsConnectionFields }> }
        | { __typename: 'User' }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type CommitAncestorsConnectionFields = { nodes: Array<GitCommitFields>; pageInfo: { hasNextPage: boolean } }

export type SymbolsVariables = Exact<{
    repo: Scalars['ID']
    revision: Scalars['String']
    first: Maybe<Scalars['Int']>
    query: Maybe<Scalars['String']>
    includePatterns: Maybe<Array<Scalars['String']>>
}>

export type SymbolsResult = {
    node: Maybe<
        | { __typename: 'Repository'; commit: Maybe<{ symbols: SymbolConnectionFields }> }
        | { __typename: 'User' }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type SymbolConnectionFields = { pageInfo: { hasNextPage: boolean }; nodes: Array<SymbolNodeFields> }

export type SymbolNodeFields = {
    name: string
    containerName: Maybe<string>
    kind: SymbolKind
    language: string
    url: string
    location: {
        resource: { path: string }
        range: Maybe<{ start: { line: number; character: number }; end: { line: number; character: number } }>
    }
}

export type RepositoriesForPopoverVariables = Exact<{
    first: Maybe<Scalars['Int']>
    query: Maybe<Scalars['String']>
}>

export type RepositoriesForPopoverResult = {
    repositories: {
        totalCount: Maybe<number>
        nodes: Array<{ id: string; name: string }>
        pageInfo: { hasNextPage: boolean }
    }
}

export type RepositoryGitCommitVariables = Exact<{
    repo: Scalars['ID']
    first: Maybe<Scalars['Int']>
    revision: Scalars['String']
    query: Maybe<Scalars['String']>
}>

export type RepositoryGitCommitResult = {
    node: Maybe<
        | { __typename: 'Repository'; commit: Maybe<{ ancestors: GitCommitAncestorsConnectionFields }> }
        | { __typename: 'User' }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type GitCommitAncestorsConnectionFields = {
    nodes: Array<GitCommitAncestorFields>
    pageInfo: { hasNextPage: boolean }
}

export type GitCommitAncestorFields = {
    id: string
    oid: string
    abbreviatedOID: string
    subject: string
    author: { date: string; person: { name: string; avatarURL: Maybe<string> } }
}

export type ExternalLinkFields = { url: string; serviceKind: Maybe<ExternalServiceKind> }

export type RepositoryFields = {
    id: string
    name: string
    url: string
    description: string
    viewerCanAdminister: boolean
    externalURLs: Array<{ url: string; serviceKind: Maybe<ExternalServiceKind> }>
    defaultBranch: Maybe<{ displayName: string }>
}

export type RepositoryRedirectVariables = Exact<{
    repoName: Scalars['String']
}>

export type RepositoryRedirectResult = {
    repositoryRedirect: Maybe<
        ({ __typename: 'Repository' } & RepositoryFields) | { __typename: 'Redirect'; url: string }
    >
}

export type ResolveRevVariables = Exact<{
    repoName: Scalars['String']
    revision: Scalars['String']
}>

export type ResolveRevResult = {
    repositoryRedirect: Maybe<
        | {
              __typename: 'Repository'
              mirrorInfo: { cloneInProgress: boolean; cloneProgress: Maybe<string>; cloned: boolean }
              commit: Maybe<{ oid: string; tree: Maybe<{ url: string }> }>
              defaultBranch: Maybe<{ abbrevName: string }>
          }
        | { __typename: 'Redirect'; url: string }
    >
}

export type HighlightedFileVariables = Exact<{
    repoName: Scalars['String']
    commitID: Scalars['String']
    filePath: Scalars['String']
    disableTimeout: Scalars['Boolean']
    isLightTheme: Scalars['Boolean']
    ranges: Array<HighlightLineRange>
}>

export type HighlightedFileResult = {
    repository: Maybe<{
        commit: Maybe<{
            file: Maybe<
                | {
                      isDirectory: boolean
                      richHTML: string
                      highlight: { aborted: boolean; lineRanges: Array<Array<string>> }
                  }
                | {
                      isDirectory: boolean
                      richHTML: string
                      highlight: { aborted: boolean; lineRanges: Array<Array<string>> }
                  }
            >
        }>
    }>
}

export type FileExternalLinksVariables = Exact<{
    repoName: Scalars['String']
    revision: Scalars['String']
    filePath: Scalars['String']
}>

export type FileExternalLinksResult = {
    repository: Maybe<{
        commit: Maybe<{
            file: Maybe<{ externalURLs: Array<ExternalLinkFields> } | { externalURLs: Array<ExternalLinkFields> }>
        }>
    }>
}

export type TreeEntriesVariables = Exact<{
    repoName: Scalars['String']
    revision: Scalars['String']
    commitID: Scalars['String']
    filePath: Scalars['String']
    first: Maybe<Scalars['Int']>
}>

export type TreeEntriesResult = { repository: Maybe<{ commit: Maybe<{ tree: Maybe<TreeFields> }> }> }

export type TreeFields = {
    isRoot: boolean
    url: string
    entries: Array<TreeEntryFields_GitTree_ | TreeEntryFields_GitBlob_>
}

type TreeEntryFields_GitTree_ = {
    name: string
    path: string
    isDirectory: boolean
    url: string
    isSingleChild: boolean
    submodule: Maybe<{ url: string; commit: string }>
}

type TreeEntryFields_GitBlob_ = {
    name: string
    path: string
    isDirectory: boolean
    url: string
    isSingleChild: boolean
    submodule: Maybe<{ url: string; commit: string }>
}

export type TreeEntryFields = TreeEntryFields_GitTree_ | TreeEntryFields_GitBlob_

export type BlobVariables = Exact<{
    repoName: Scalars['String']
    commitID: Scalars['String']
    filePath: Scalars['String']
    isLightTheme: Scalars['Boolean']
    disableTimeout: Scalars['Boolean']
}>

export type BlobResult = {
    repository: Maybe<{
        commit: Maybe<{
            file: Maybe<
                | { content: string; richHTML: string; highlight: { aborted: boolean; html: string } }
                | { content: string; richHTML: string; highlight: { aborted: boolean; html: string } }
            >
        }>
    }>
}

export type RepositoryGitBranchesOverviewVariables = Exact<{
    repo: Scalars['ID']
    first: Scalars['Int']
    withBehindAhead: Scalars['Boolean']
}>

export type RepositoryGitBranchesOverviewResult = {
    node: Maybe<{
        defaultBranch: Maybe<GitRefFields>
        gitRefs: { nodes: Array<GitRefFields>; pageInfo: { hasNextPage: boolean } }
    }>
}

export type RepositoryCommitVariables = Exact<{
    repo: Scalars['ID']
    revspec: Scalars['String']
}>

export type RepositoryCommitResult = {
    node: Maybe<
        | { __typename: 'Repository'; commit: Maybe<{ __typename: 'GitCommit' } & GitCommitFields> }
        | { __typename: 'User' }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type GitCommitFields = {
    id: string
    oid: string
    abbreviatedOID: string
    message: string
    subject: string
    body: Maybe<string>
    url: string
    canonicalURL: string
    author: SignatureFields
    committer: Maybe<SignatureFields>
    parents: Array<{ oid: string; abbreviatedOID: string; url: string }>
    externalURLs: Array<ExternalLinkFields>
    tree: Maybe<{ canonicalURL: string }>
}

export type SignatureFields = {
    date: string
    person: {
        avatarURL: Maybe<string>
        name: string
        email: string
        displayName: string
        user: Maybe<{ id: string; username: string; url: string; displayName: Maybe<string> }>
    }
}

export type RepositoryGitCommitsVariables = Exact<{
    repo: Scalars['ID']
    revspec: Scalars['String']
    first: Maybe<Scalars['Int']>
    query: Maybe<Scalars['String']>
}>

export type RepositoryGitCommitsResult = {
    node: Maybe<{ commit: Maybe<{ ancestors: { nodes: Array<GitCommitFields>; pageInfo: { hasNextPage: boolean } } }> }>
}

export type RepositoryComparisonCommitsVariables = Exact<{
    repo: Scalars['ID']
    base: Maybe<Scalars['String']>
    head: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
}>

export type RepositoryComparisonCommitsResult = {
    node: Maybe<{ comparison: { commits: { nodes: Array<GitCommitFields>; pageInfo: { hasNextPage: boolean } } } }>
}

export type RepositoryComparisonDiffVariables = Exact<{
    repo: Scalars['ID']
    base: Maybe<Scalars['String']>
    head: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
    isLightTheme: Scalars['Boolean']
}>

export type RepositoryComparisonDiffResult = {
    node: Maybe<{
        comparison: {
            fileDiffs: {
                totalCount: Maybe<number>
                nodes: Array<FileDiffFields>
                pageInfo: { endCursor: Maybe<string>; hasNextPage: boolean }
                diffStat: DiffStatFields
            }
        }
    }>
}

export type RepositoryComparisonVariables = Exact<{
    repo: Scalars['ID']
    base: Maybe<Scalars['String']>
    head: Maybe<Scalars['String']>
}>

export type RepositoryComparisonResult = {
    node: Maybe<{
        comparison: {
            range: {
                expr: string
                baseRevSpec: { object: Maybe<{ oid: string }> }
                headRevSpec: { object: Maybe<{ oid: string }> }
            }
        }
    }>
}

export type RepositoryTextSearchIndexVariables = Exact<{
    id: Scalars['ID']
}>

export type RepositoryTextSearchIndexResult = {
    node: Maybe<{
        textSearchIndex: Maybe<{
            status: Maybe<{
                updatedAt: string
                contentByteSize: number
                contentFilesCount: number
                indexByteSize: number
                indexShardsCount: number
                newLinesCount: number
                defaultBranchNewLinesCount: number
                otherBranchesNewLinesCount: number
            }>
            refs: Array<{
                indexed: boolean
                current: boolean
                ref: { displayName: string; url: string }
                indexedCommit: Maybe<{ oid: string; abbreviatedOID: string; commit: Maybe<{ url: string }> }>
            }>
        }>
    }>
}

export type SettingsAreaRepositoryFields = {
    id: string
    name: string
    url: string
    isPrivate: boolean
    viewerCanAdminister: boolean
    mirrorInfo: {
        remoteURL: string
        cloneInProgress: boolean
        cloneProgress: Maybe<string>
        cloned: boolean
        updatedAt: Maybe<string>
        updateSchedule: Maybe<{ due: string; index: number; total: number }>
        updateQueue: Maybe<{ updating: boolean; index: number; total: number }>
    }
    externalServices: { nodes: Array<{ id: string; kind: ExternalServiceKind; displayName: string }> }
}

export type SettingsAreaRepositoryVariables = Exact<{
    name: Scalars['String']
}>

export type SettingsAreaRepositoryResult = { repository: Maybe<SettingsAreaRepositoryFields> }

export type RepositoryContributorsVariables = Exact<{
    repo: Scalars['ID']
    first: Maybe<Scalars['Int']>
    revisionRange: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    path: Maybe<Scalars['String']>
}>

export type RepositoryContributorsResult = {
    node: Maybe<{
        contributors: {
            totalCount: number
            nodes: Array<{
                count: number
                person: {
                    name: string
                    displayName: string
                    email: string
                    avatarURL: Maybe<string>
                    user: Maybe<{ username: string; url: string }>
                }
                commits: {
                    nodes: Array<{
                        oid: string
                        abbreviatedOID: string
                        url: string
                        subject: string
                        author: { date: string }
                    }>
                }
            }>
            pageInfo: { hasNextPage: boolean }
        }
    }>
}

export type TreeCommitsVariables = Exact<{
    repo: Scalars['ID']
    revspec: Scalars['String']
    first: Maybe<Scalars['Int']>
    filePath: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
}>

export type TreeCommitsResult = {
    node: Maybe<
        | {
              __typename: 'Repository'
              commit: Maybe<{ ancestors: { nodes: Array<GitCommitFields>; pageInfo: { hasNextPage: boolean } } }>
          }
        | { __typename: 'User' }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type TreePageRepositoryFields = { id: string; name: string; description: string; viewerCanAdminister: boolean }

export type SearchVariables = Exact<{
    query: Scalars['String']
    version: SearchVersion
    patternType: SearchPatternType
    versionContext: Maybe<Scalars['String']>
}>

export type SearchResult = {
    search: Maybe<{
        results: {
            __typename: 'SearchResults'
            limitHit: boolean
            matchCount: number
            approximateResultCount: string
            repositoriesCount: number
            indexUnavailable: boolean
            elapsedMilliseconds: number
            missing: Array<{ name: string }>
            cloning: Array<{ name: string }>
            timedout: Array<{ name: string }>
            dynamicFilters: Array<{ value: string; label: string; count: number; limitHit: boolean; kind: string }>
            results: Array<
                | {
                      __typename: 'FileMatch'
                      limitHit: boolean
                      file: { path: string; url: string; commit: { oid: string } }
                      repository: { name: string; url: string }
                      revSpec: Maybe<
                          | { __typename: 'GitRef'; displayName: string; url: string }
                          | {
                                __typename: 'GitRevSpecExpr'
                                expr: string
                                object: Maybe<{ commit: Maybe<{ url: string }> }>
                            }
                          | { __typename: 'GitObject'; abbreviatedOID: string; commit: Maybe<{ url: string }> }
                      >
                      symbols: Array<{ name: string; containerName: Maybe<string>; url: string; kind: SymbolKind }>
                      lineMatches: Array<{
                          preview: string
                          lineNumber: number
                          offsetAndLengths: Array<Array<number>>
                      }>
                  }
                | {
                      __typename: 'CommitSearchResult'
                      url: string
                      label: { html: string }
                      detail: { html: string }
                      matches: Array<{
                          url: string
                          body: { text: string; html: string }
                          highlights: Array<{ line: number; character: number; length: number }>
                      }>
                  }
                | {
                      __typename: 'Repository'
                      id: string
                      name: string
                      url: string
                      label: { html: string }
                      detail: { html: string }
                      matches: Array<{
                          url: string
                          body: { text: string; html: string }
                          highlights: Array<{ line: number; character: number; length: number }>
                      }>
                  }
            >
            alert: Maybe<{
                title: string
                description: Maybe<string>
                proposedQueries: Maybe<Array<{ description: Maybe<string>; query: string }>>
            }>
        }
    }>
}

export type RepoGroupsVariables = Exact<{ [key: string]: never }>

export type RepoGroupsResult = { repoGroups: Array<{ __typename: 'RepoGroup'; name: string }> }

export type SearchContextFields = {
    __typename: 'SearchContext'
    id: string
    name: string
    spec: string
    description: string
    public: boolean
    autoDefined: boolean
    updatedAt: string
    viewerCanManage: boolean
    namespace: Maybe<
        | { __typename: 'User'; id: string; namespaceName: string }
        | { __typename: 'Org'; id: string; namespaceName: string }
    >
    repositories: Array<{
        __typename: 'SearchContextRepositoryRevisions'
        revisions: Array<string>
        repository: { name: string }
    }>
}

export type ConvertVersionContextToSearchContextVariables = Exact<{
    name: Scalars['String']
}>

export type ConvertVersionContextToSearchContextResult = {
    convertVersionContextToSearchContext: { id: string; spec: string }
}

export type AutoDefinedSearchContextsVariables = Exact<{ [key: string]: never }>

export type AutoDefinedSearchContextsResult = { autoDefinedSearchContexts: Array<SearchContextFields> }

export type ListSearchContextsVariables = Exact<{
    first: Scalars['Int']
    after: Maybe<Scalars['String']>
    query: Maybe<Scalars['String']>
    namespaceFilterType: Maybe<SearchContextsNamespaceFilterType>
    namespace: Maybe<Scalars['ID']>
    orderBy: Maybe<SearchContextsOrderBy>
    descending: Maybe<Scalars['Boolean']>
}>

export type ListSearchContextsResult = {
    searchContexts: {
        totalCount: number
        nodes: Array<SearchContextFields>
        pageInfo: { hasNextPage: boolean; endCursor: Maybe<string> }
    }
}

export type FetchSearchContextVariables = Exact<{
    id: Scalars['ID']
}>

export type FetchSearchContextResult = { node: Maybe<SearchContextFields> }

export type CreateSearchContextVariables = Exact<{
    searchContext: SearchContextInput
    repositories: Array<SearchContextRepositoryRevisionsInput>
}>

export type CreateSearchContextResult = { createSearchContext: SearchContextFields }

export type UpdateSearchContextVariables = Exact<{
    id: Scalars['ID']
    searchContext: SearchContextEditInput
    repositories: Array<SearchContextRepositoryRevisionsInput>
}>

export type UpdateSearchContextResult = { updateSearchContext: SearchContextFields }

export type IsSearchContextAvailableVariables = Exact<{
    spec: Scalars['String']
}>

export type IsSearchContextAvailableResult = { isSearchContextAvailable: boolean }

export type SearchSuggestionsVariables = Exact<{
    query: Scalars['String']
}>

export type SearchSuggestionsResult = {
    search: Maybe<{
        suggestions: Array<
            | { __typename: 'Repository'; name: string }
            | {
                  __typename: 'File'
                  path: string
                  name: string
                  isDirectory: boolean
                  url: string
                  repository: { name: string }
              }
            | {
                  __typename: 'Symbol'
                  name: string
                  containerName: Maybe<string>
                  url: string
                  kind: SymbolKind
                  location: { resource: { path: string; repository: { name: string } } }
              }
            | { __typename: 'Language' }
        >
    }>
}

export type ReposByQueryVariables = Exact<{
    query: Scalars['String']
}>

export type ReposByQueryResult = { search: Maybe<{ results: { repositories: Array<{ name: string; url: string }> } }> }

export type SavedSearchFields = {
    id: string
    description: string
    notify: boolean
    notifySlack: boolean
    query: string
    slackWebhookURL: Maybe<string>
    namespace:
        | { __typename: 'User'; id: string; namespaceName: string }
        | { __typename: 'Org'; id: string; namespaceName: string }
}

export type savedSearchesVariables = Exact<{ [key: string]: never }>

export type savedSearchesResult = { savedSearches: Array<SavedSearchFields> }

export type SavedSearchVariables = Exact<{
    id: Scalars['ID']
}>

export type SavedSearchResult = {
    node: Maybe<{
        id: string
        description: string
        query: string
        notify: boolean
        notifySlack: boolean
        slackWebhookURL: Maybe<string>
        namespace: { id: string } | { id: string }
    }>
}

export type CreateSavedSearchVariables = Exact<{
    description: Scalars['String']
    query: Scalars['String']
    notifyOwner: Scalars['Boolean']
    notifySlack: Scalars['Boolean']
    userID: Maybe<Scalars['ID']>
    orgID: Maybe<Scalars['ID']>
}>

export type CreateSavedSearchResult = { createSavedSearch: SavedSearchFields }

export type UpdateSavedSearchVariables = Exact<{
    id: Scalars['ID']
    description: Scalars['String']
    query: Scalars['String']
    notifyOwner: Scalars['Boolean']
    notifySlack: Scalars['Boolean']
    userID: Maybe<Scalars['ID']>
    orgID: Maybe<Scalars['ID']>
}>

export type UpdateSavedSearchResult = { updateSavedSearch: SavedSearchFields }

export type DeleteSavedSearchVariables = Exact<{
    id: Scalars['ID']
}>

export type DeleteSavedSearchResult = { deleteSavedSearch: Maybe<{ alwaysNil: Maybe<string> }> }

export type highlightCodeVariables = Exact<{
    code: Scalars['String']
    fuzzyLanguage: Scalars['String']
    disableTimeout: Scalars['Boolean']
    isLightTheme: Scalars['Boolean']
}>

export type highlightCodeResult = { highlightCode: string }

export type ManyReposWarningVariables = Exact<{
    first: Maybe<Scalars['Int']>
}>

export type ManyReposWarningResult = { repositories: { nodes: Array<{ id: string }> } }

export type EventLogsDataVariables = Exact<{
    userId: Scalars['ID']
    first: Maybe<Scalars['Int']>
    eventName: Scalars['String']
}>

export type EventLogsDataResult = {
    node: Maybe<{
        eventLogs: {
            totalCount: number
            nodes: Array<{ argument: Maybe<string>; timestamp: string; url: string }>
            pageInfo: { hasNextPage: boolean }
        }
    }>
}

export type SettingsCascadeVariables = Exact<{
    subject: Scalars['ID']
}>

export type SettingsCascadeResult = {
    settingsSubject: Maybe<
        | {
              settingsCascade: {
                  subjects: Array<
                      | { latestSettings: Maybe<{ id: number; contents: string }> }
                      | { latestSettings: Maybe<{ id: number; contents: string }> }
                      | { latestSettings: Maybe<{ id: number; contents: string }> }
                      | { latestSettings: Maybe<{ id: number; contents: string }> }
                  >
              }
          }
        | {
              settingsCascade: {
                  subjects: Array<
                      | { latestSettings: Maybe<{ id: number; contents: string }> }
                      | { latestSettings: Maybe<{ id: number; contents: string }> }
                      | { latestSettings: Maybe<{ id: number; contents: string }> }
                      | { latestSettings: Maybe<{ id: number; contents: string }> }
                  >
              }
          }
        | {
              settingsCascade: {
                  subjects: Array<
                      | { latestSettings: Maybe<{ id: number; contents: string }> }
                      | { latestSettings: Maybe<{ id: number; contents: string }> }
                      | { latestSettings: Maybe<{ id: number; contents: string }> }
                      | { latestSettings: Maybe<{ id: number; contents: string }> }
                  >
              }
          }
        | {
              settingsCascade: {
                  subjects: Array<
                      | { latestSettings: Maybe<{ id: number; contents: string }> }
                      | { latestSettings: Maybe<{ id: number; contents: string }> }
                      | { latestSettings: Maybe<{ id: number; contents: string }> }
                      | { latestSettings: Maybe<{ id: number; contents: string }> }
                  >
              }
          }
    >
}

export type AccessTokenFields = {
    id: string
    scopes: Array<string>
    note: string
    createdAt: string
    lastUsedAt: Maybe<string>
    subject: { username: string }
    creator: { username: string }
}

export type DeleteAccessTokenVariables = Exact<{
    tokenID: Scalars['ID']
}>

export type DeleteAccessTokenResult = { deleteAccessToken: { alwaysNil: Maybe<string> } }

export type SiteAdminAccessTokensVariables = Exact<{
    first: Maybe<Scalars['Int']>
}>

export type SiteAdminAccessTokensResult = { site: { accessTokens: SiteAdminAccessTokenConnectionFields } }

export type SiteAdminAccessTokenConnectionFields = {
    totalCount: number
    nodes: Array<AccessTokenFields>
    pageInfo: { hasNextPage: boolean }
}

export type UsersVariables = Exact<{
    first: Maybe<Scalars['Int']>
    query: Maybe<Scalars['String']>
}>

export type UsersResult = {
    users: {
        totalCount: number
        nodes: Array<{
            id: string
            username: string
            displayName: Maybe<string>
            createdAt: string
            siteAdmin: boolean
            emails: Array<{
                email: string
                verified: boolean
                verificationPending: boolean
                viewerCanManuallyVerify: boolean
            }>
            latestSettings: Maybe<{ createdAt: string; contents: string }>
            organizations: { nodes: Array<{ name: string }> }
        }>
    }
}

export type OrganizationsVariables = Exact<{
    first: Maybe<Scalars['Int']>
    query: Maybe<Scalars['String']>
}>

export type OrganizationsResult = { organizations: OrganizationsConnectionFields }

export type OrganizationsConnectionFields = { totalCount: number; nodes: Array<OrganizationFields> }

export type OrganizationFields = {
    id: string
    name: string
    displayName: Maybe<string>
    createdAt: string
    latestSettings: Maybe<{ createdAt: string; contents: string }>
    members: { totalCount: number }
}

export type SiteAdminRepositoryFields = {
    id: string
    name: string
    createdAt: string
    viewerCanAdminister: boolean
    url: string
    isPrivate: boolean
    mirrorInfo: { cloned: boolean; cloneInProgress: boolean; updatedAt: Maybe<string> }
    externalRepository: { serviceType: string; serviceID: string }
}

export type UserRepositoriesVariables = Exact<{
    id: Scalars['ID']
    first: Maybe<Scalars['Int']>
    query: Maybe<Scalars['String']>
    cloned: Maybe<Scalars['Boolean']>
    notCloned: Maybe<Scalars['Boolean']>
    indexed: Maybe<Scalars['Boolean']>
    notIndexed: Maybe<Scalars['Boolean']>
    externalServiceID: Maybe<Scalars['ID']>
}>

export type UserRepositoriesResult = {
    node: Maybe<{
        repositories: {
            totalCount: Maybe<number>
            nodes: Array<SiteAdminRepositoryFields>
            pageInfo: { hasNextPage: boolean }
        }
    }>
}

export type RepositoriesVariables = Exact<{
    first: Maybe<Scalars['Int']>
    query: Maybe<Scalars['String']>
    cloned: Maybe<Scalars['Boolean']>
    notCloned: Maybe<Scalars['Boolean']>
    indexed: Maybe<Scalars['Boolean']>
    notIndexed: Maybe<Scalars['Boolean']>
}>

export type RepositoriesResult = {
    repositories: {
        totalCount: Maybe<number>
        nodes: Array<SiteAdminRepositoryFields>
        pageInfo: { hasNextPage: boolean }
    }
}

export type UpdateMirrorRepositoryVariables = Exact<{
    repository: Scalars['ID']
}>

export type UpdateMirrorRepositoryResult = { updateMirrorRepository: { alwaysNil: Maybe<string> } }

export type CheckMirrorRepositoryConnectionVariables = Exact<{
    repository: Maybe<Scalars['ID']>
    name: Maybe<Scalars['String']>
}>

export type CheckMirrorRepositoryConnectionResult = { checkMirrorRepositoryConnection: { error: Maybe<string> } }

export type ScheduleRepositoryPermissionsSyncVariables = Exact<{
    repository: Scalars['ID']
}>

export type ScheduleRepositoryPermissionsSyncResult = {
    scheduleRepositoryPermissionsSync: { alwaysNil: Maybe<string> }
}

export type UserUsageStatisticsVariables = Exact<{
    activePeriod: Maybe<UserActivePeriod>
    query: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
}>

export type UserUsageStatisticsResult = {
    users: {
        totalCount: number
        nodes: Array<{
            id: string
            username: string
            usageStatistics: {
                searchQueries: number
                pageViews: number
                codeIntelligenceActions: number
                lastActiveTime: Maybe<string>
                lastActiveCodeHostIntegrationTime: Maybe<string>
            }
        }>
    }
}

export type SiteUsageStatisticsVariables = Exact<{ [key: string]: never }>

export type SiteUsageStatisticsResult = {
    site: {
        usageStatistics: {
            daus: Array<{
                userCount: number
                registeredUserCount: number
                anonymousUserCount: number
                startTime: string
            }>
            waus: Array<{
                userCount: number
                registeredUserCount: number
                anonymousUserCount: number
                startTime: string
            }>
            maus: Array<{
                userCount: number
                registeredUserCount: number
                anonymousUserCount: number
                startTime: string
            }>
        }
    }
}

export type SiteVariables = Exact<{ [key: string]: never }>

export type SiteResult = {
    site: {
        id: string
        canReloadSite: boolean
        configuration: { id: number; effectiveContents: string; validationMessages: Array<string> }
    }
}

export type AllConfigVariables = Exact<{
    first: Maybe<Scalars['Int']>
}>

export type AllConfigResult = {
    site: {
        id: string
        configuration: { id: number; effectiveContents: string }
        latestSettings: Maybe<{ contents: string }>
        settingsCascade: { final: string }
    }
    externalServices: {
        nodes: Array<{
            id: string
            kind: ExternalServiceKind
            displayName: string
            config: string
            createdAt: string
            updatedAt: string
            warning: Maybe<string>
        }>
    }
    viewerSettings: SiteAdminSettingsCascadeFields
}

export type SiteAdminSettingsCascadeFields = {
    final: string
    subjects: Array<
        | { __typename: 'User'; settingsURL: Maybe<string>; latestSettings: Maybe<{ id: number; contents: string }> }
        | { __typename: 'Org'; settingsURL: Maybe<string>; latestSettings: Maybe<{ id: number; contents: string }> }
        | {
              __typename: 'DefaultSettings'
              settingsURL: Maybe<string>
              latestSettings: Maybe<{ id: number; contents: string }>
          }
        | { __typename: 'Site'; settingsURL: Maybe<string>; latestSettings: Maybe<{ id: number; contents: string }> }
    >
}

export type UpdateSiteConfigurationVariables = Exact<{
    lastID: Scalars['Int']
    input: Scalars['String']
}>

export type UpdateSiteConfigurationResult = { updateSiteConfiguration: boolean }

export type ReloadSiteVariables = Exact<{ [key: string]: never }>

export type ReloadSiteResult = { reloadSite: Maybe<{ alwaysNil: Maybe<string> }> }

export type SetUserIsSiteAdminVariables = Exact<{
    userID: Scalars['ID']
    siteAdmin: Scalars['Boolean']
}>

export type SetUserIsSiteAdminResult = { setUserIsSiteAdmin: Maybe<{ alwaysNil: Maybe<string> }> }

export type InvalidateSessionsByIDVariables = Exact<{
    userID: Scalars['ID']
}>

export type InvalidateSessionsByIDResult = { invalidateSessionsByID: Maybe<{ alwaysNil: Maybe<string> }> }

export type RandomizeUserPasswordVariables = Exact<{
    user: Scalars['ID']
}>

export type RandomizeUserPasswordResult = { randomizeUserPassword: { resetPasswordURL: Maybe<string> } }

export type DeleteUserVariables = Exact<{
    user: Scalars['ID']
    hard: Maybe<Scalars['Boolean']>
}>

export type DeleteUserResult = { deleteUser: Maybe<{ alwaysNil: Maybe<string> }> }

export type CreateUserVariables = Exact<{
    username: Scalars['String']
    email: Maybe<Scalars['String']>
}>

export type CreateUserResult = { createUser: { resetPasswordURL: Maybe<string> } }

export type DeleteOrganizationVariables = Exact<{
    organization: Scalars['ID']
}>

export type DeleteOrganizationResult = { deleteOrganization: Maybe<{ alwaysNil: Maybe<string> }> }

export type SiteUpdateCheckVariables = Exact<{ [key: string]: never }>

export type SiteUpdateCheckResult = {
    site: {
        buildVersion: string
        productVersion: string
        updateCheck: {
            pending: boolean
            checkedAt: Maybe<string>
            errorMessage: Maybe<string>
            updateVersionAvailable: Maybe<string>
        }
    }
}

export type SiteMonitoringStatisticsVariables = Exact<{
    days: Scalars['Int']
}>

export type SiteMonitoringStatisticsResult = {
    site: {
        monitoringStatistics: {
            alerts: Array<{ serviceName: string; name: string; timestamp: string; average: number; owner: string }>
        }
    }
}

export type UserPublicRepositoriesVariables = Exact<{
    userId: Scalars['ID']
}>

export type UserPublicRepositoriesResult = { node: Maybe<{ publicRepositories: Array<UserPublicRepositoriesFields> }> }

export type UserPublicRepositoriesFields = { id: string; name: string }

export type SetUserPublicRepositoriesVariables = Exact<{
    userId: Scalars['ID']
    repos: Array<Scalars['String']>
}>

export type SetUserPublicRepositoriesResult = { SetUserPublicRepos: { alwaysNil: Maybe<string> } }

export type OutOfBandMigrationsVariables = Exact<{ [key: string]: never }>

export type OutOfBandMigrationsResult = { outOfBandMigrations: Array<OutOfBandMigrationFields> }

export type OutOfBandMigrationFields = {
    id: string
    team: string
    component: string
    description: string
    introduced: string
    deprecated: Maybe<string>
    progress: number
    created: string
    lastUpdated: Maybe<string>
    nonDestructive: boolean
    applyReverse: boolean
    errors: Array<{ message: string; created: string }>
}

export type OverviewVariables = Exact<{ [key: string]: never }>

export type OverviewResult = {
    repositories: { totalCount: Maybe<number> }
    repositoryStats: { gitDirBytes: string; indexedLinesCount: string }
    users: { totalCount: number }
    organizations: { totalCount: number }
    surveyResponses: { totalCount: number; averageScore: number }
}

export type WAUsVariables = Exact<{ [key: string]: never }>

export type WAUsResult = {
    site: {
        usageStatistics: {
            waus: Array<{
                userCount: number
                registeredUserCount: number
                anonymousUserCount: number
                startTime: string
            }>
        }
    }
}

export type SiteFlagsVariables = Exact<{ [key: string]: never }>

export type SiteFlagsResult = {
    site: {
        needsRepositoryConfiguration: boolean
        freeUsersExceeded: boolean
        disableBuiltInSearches: boolean
        sendsEmailVerificationEmails: boolean
        productVersion: string
        alerts: Array<{ type: AlertType; message: string; isDismissibleWithKey: Maybe<string> }>
        authProviders: {
            nodes: Array<{
                serviceType: string
                serviceID: string
                clientID: string
                displayName: string
                isBuiltin: boolean
                authenticationURL: Maybe<string>
            }>
        }
        updateCheck: {
            pending: boolean
            checkedAt: Maybe<string>
            errorMessage: Maybe<string>
            updateVersionAvailable: Maybe<string>
        }
        productSubscription: { noLicenseWarningUserCount: Maybe<number>; license: Maybe<{ expiresAt: string }> }
    }
}

export type SiteAdminActivationStatusVariables = Exact<{ [key: string]: never }>

export type SiteAdminActivationStatusResult = {
    externalServices: { totalCount: number }
    repositories: { totalCount: Maybe<number> }
    repositoryStats: { gitDirBytes: string; indexedLinesCount: string }
    viewerSettings: { final: string }
    users: { totalCount: number }
    currentUser: Maybe<{
        usageStatistics: { searchQueries: number; findReferencesActions: number; codeIntelligenceActions: number }
    }>
}

export type ActivationStatusVariables = Exact<{ [key: string]: never }>

export type ActivationStatusResult = {
    currentUser: Maybe<{
        usageStatistics: { searchQueries: number; findReferencesActions: number; codeIntelligenceActions: number }
    }>
}

export type LinksForRepositoriesVariables = Exact<{ [key: string]: never }>

export type LinksForRepositoriesResult = {
    repositories: { nodes: Array<{ url: string; gitRefs: { totalCount: number } }> }
}

export type UserAreaUserFields = {
    __typename: 'User'
    id: string
    username: string
    displayName: Maybe<string>
    url: string
    settingsURL: Maybe<string>
    avatarURL: Maybe<string>
    viewerCanAdminister: boolean
    siteAdmin: boolean
    builtinAuth: boolean
    createdAt: string
    tags: Array<string>
    emails: Array<{ email: string; verified: boolean }>
    organizations: { nodes: Array<{ id: string; displayName: Maybe<string>; name: string }> }
} & EditUserProfilePage

export type UserAreaVariables = Exact<{
    username: Scalars['String']
    siteAdmin: Scalars['Boolean']
}>

export type UserAreaResult = { user: Maybe<UserAreaUserFields & EditUserProfilePage> }

export type CreateAccessTokenVariables = Exact<{
    user: Scalars['ID']
    scopes: Array<Scalars['String']>
    note: Scalars['String']
}>

export type CreateAccessTokenResult = { createAccessToken: { id: string; token: string } }

export type AccessTokensVariables = Exact<{
    user: Scalars['ID']
    first: Maybe<Scalars['Int']>
}>

export type AccessTokensResult = {
    node: Maybe<
        | { __typename: 'Repository' }
        | { __typename: 'User'; accessTokens: AccessTokensConnectionFields }
        | { __typename: 'Campaign' }
        | { __typename: 'CampaignsCredential' }
        | { __typename: 'CampaignSpec' }
        | { __typename: 'Org' }
        | { __typename: 'HiddenExternalChangeset' }
        | { __typename: 'ExternalChangeset' }
        | { __typename: 'ChangesetEvent' }
        | { __typename: 'HiddenChangesetSpec' }
        | { __typename: 'VisibleChangesetSpec' }
        | { __typename: 'BatchChange' }
        | { __typename: 'BulkOperation' }
        | { __typename: 'BatchSpec' }
        | { __typename: 'BatchChangesCredential' }
        | { __typename: 'Monitor' }
        | { __typename: 'MonitorQuery' }
        | { __typename: 'MonitorTriggerEvent' }
        | { __typename: 'MonitorEmail' }
        | { __typename: 'MonitorActionEvent' }
        | { __typename: 'LSIFUpload' }
        | { __typename: 'LSIFIndex' }
        | { __typename: 'ProductLicense' }
        | { __typename: 'ProductSubscription' }
        | { __typename: 'OutOfBandMigration' }
        | { __typename: 'SavedSearch' }
        | { __typename: 'ExternalService' }
        | { __typename: 'SearchContext' }
        | { __typename: 'GitRef' }
        | { __typename: 'GitCommit' }
        | { __typename: 'AccessToken' }
        | { __typename: 'ExternalAccount' }
        | { __typename: 'OrganizationInvitation' }
        | { __typename: 'RegistryExtension' }
    >
}

export type AccessTokensConnectionFields = {
    totalCount: number
    nodes: Array<AccessTokenFields>
    pageInfo: { hasNextPage: boolean }
}

export type MinExternalAccountsVariables = Exact<{
    username: Scalars['String']
}>

export type MinExternalAccountsResult = {
    user: Maybe<{
        externalAccounts: {
            nodes: Array<{ id: string; serviceID: string; serviceType: string; accountData: Maybe<unknown> }>
        }
    }>
}

export type UpdatePasswordVariables = Exact<{
    oldPassword: Scalars['String']
    newPassword: Scalars['String']
}>

export type UpdatePasswordResult = { updatePassword: Maybe<{ alwaysNil: Maybe<string> }> }

export type CreatePasswordVariables = Exact<{
    newPassword: Scalars['String']
}>

export type CreatePasswordResult = { createPassword: Maybe<{ alwaysNil: Maybe<string> }> }

export type SetUserEmailVerifiedVariables = Exact<{
    user: Scalars['ID']
    email: Scalars['String']
    verified: Scalars['Boolean']
}>

export type SetUserEmailVerifiedResult = { setUserEmailVerified: { alwaysNil: Maybe<string> } }

export type LogUserEventVariables = Exact<{
    event: UserEvent
    userCookieID: Scalars['String']
}>

export type LogUserEventResult = { logUserEvent: Maybe<{ alwaysNil: Maybe<string> }> }

export type LogEventVariables = Exact<{
    event: Scalars['String']
    userCookieID: Scalars['String']
    firstSourceURL: Scalars['String']
    url: Scalars['String']
    source: EventSource
    argument: Maybe<Scalars['String']>
}>

export type LogEventResult = { logEvent: Maybe<{ alwaysNil: Maybe<string> }> }

export type AddUserEmailVariables = Exact<{
    user: Scalars['ID']
    email: Scalars['String']
}>

export type AddUserEmailResult = { addUserEmail: { alwaysNil: Maybe<string> } }

export type SetUserEmailPrimaryVariables = Exact<{
    user: Scalars['ID']
    email: Scalars['String']
}>

export type SetUserEmailPrimaryResult = { setUserEmailPrimary: { alwaysNil: Maybe<string> } }

export type RemoveUserEmailVariables = Exact<{
    user: Scalars['ID']
    email: Scalars['String']
}>

export type RemoveUserEmailResult = { removeUserEmail: { alwaysNil: Maybe<string> } }

export type ResendVerificationEmailVariables = Exact<{
    user: Scalars['ID']
    email: Scalars['String']
}>

export type ResendVerificationEmailResult = { resendVerificationEmail: { alwaysNil: Maybe<string> } }

export type UserEmailsVariables = Exact<{
    user: Scalars['ID']
}>

export type UserEmailsResult = {
    node: Maybe<{
        emails: Array<{
            email: string
            isPrimary: boolean
            verified: boolean
            verificationPending: boolean
            viewerCanManuallyVerify: boolean
        }>
    }>
}

export type UpdateUserVariables = Exact<{
    user: Scalars['ID']
    username: Scalars['String']
    displayName: Maybe<Scalars['String']>
    avatarURL: Maybe<Scalars['String']>
    siteAdmin: Scalars['Boolean']
}>

export type UpdateUserResult = { updateUser: UserAreaUserFields }

export type EditUserProfilePage = {
    id: string
    username: string
    displayName: Maybe<string>
    avatarURL: Maybe<string>
    viewerCanChangeUsername: boolean
    createdAt: string
}
