export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }

export interface SharedGraphQlOperations {
    /** shared/src/backend/repo.ts */
    ResolveRawRepoName: (variables: ResolveRawRepoNameVariables) => ResolveRawRepoNameResult

    /** shared/src/extensions/helpers.ts */
    Extensions: (variables: ExtensionsVariables) => ExtensionsResult

    /** shared/src/settings/edit.ts */
    EditSettings: (variables: EditSettingsVariables) => EditSettingsResult

    /** shared/src/settings/edit.ts */
    OverwriteSettings: (variables: OverwriteSettingsVariables) => OverwriteSettingsResult
}

/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    /** A triple that represents all possible states of the published value: true, false or 'draft'. */
    PublishedValue: boolean | 'draft'
    /** A valid JSON value. */
    JSONValue: unknown
    /** A string that contains valid JSON, with additional support for //-style comments and trailing commas. */
    JSONCString: string
    /** A Git object ID (SHA-1 hash, 40 hexadecimal characters). */
    GitObjectID: string
    /** An arbitrarily large integer encoded as a decimal string. */
    BigInt: string
    /**
     * An RFC 3339-encoded UTC date string, such as 1973-11-29T21:33:09Z. This value can be parsed into a
     * JavaScript Date using Date.parse. To produce this value from a JavaScript Date instance, use
     * Date#toISOString.
     */
    DateTime: string
}

/** A user (identified either by username or email address) with its repository permission. */
export interface UserPermission {
    /**
     * Depending on the bindID option in the permissions.userMapping site configuration property,
     * the elements of the list are either all usernames (bindID of "username") or all email
     * addresses (bindID of "email").
     */
    bindID: Scalars['String']
    /** The highest level of repository permission. */
    permission?: Maybe<RepositoryPermission>
}

/** Different repository permission levels. */
export enum RepositoryPermission {
    READ = 'READ',
}

/** The state of the campaign. */
export enum CampaignState {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
}

/** The publication state of a changeset on Sourcegraph */
export enum ChangesetPublicationState {
    /** The changeset has not yet been created on the code host. */
    UNPUBLISHED = 'UNPUBLISHED',
    /** The changeset has been created on the code host. */
    PUBLISHED = 'PUBLISHED',
}

/** The reconciler state of a changeset on Sourcegraph */
export enum ChangesetReconcilerState {
    /** The changeset is scheduled, and will be enqueued when its turn comes in Sourcegraph's rollout window. */
    SCHEDULED = 'SCHEDULED',
    /** The changeset is enqueued for the reconciler to process it. */
    QUEUED = 'QUEUED',
    /**
     * The changeset reconciler is currently computing the delta between the
     * If a delta exists, the reconciler tries to update the state of the
     * changeset on the code host and on Sourcegraph to the desired state.
     */
    PROCESSING = 'PROCESSING',
    /**
     * The changeset reconciler ran into a problem while processing the
     * changeset and will retry it for a number of retries.
     */
    ERRORED = 'ERRORED',
    /**
     * The changeset reconciler ran into a problem while processing the
     * changeset that can't be fixed by retrying.
     */
    FAILED = 'FAILED',
    /** The changeset is not enqueued for processing. */
    COMPLETED = 'COMPLETED',
}

/** The state of a changeset on the code host on which it's hosted. */
export enum ChangesetExternalState {
    DRAFT = 'DRAFT',
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    MERGED = 'MERGED',
    DELETED = 'DELETED',
}

/** The review state of a changeset. */
export enum ChangesetReviewState {
    APPROVED = 'APPROVED',
    CHANGES_REQUESTED = 'CHANGES_REQUESTED',
    PENDING = 'PENDING',
    COMMENTED = 'COMMENTED',
    DISMISSED = 'DISMISSED',
}

/** The state of checks (e.g., for continuous integration) on a changeset. */
export enum ChangesetCheckState {
    PENDING = 'PENDING',
    PASSED = 'PASSED',
    FAILED = 'FAILED',
}

/** The visual state a changeset is currently in. */
export enum ChangesetState {
    /** The changeset has not been marked as to be published. */
    UNPUBLISHED = 'UNPUBLISHED',
    /**
     * The changeset reconciler ran into a problem while processing the
     * changeset that can't be fixed by retrying.
     */
    FAILED = 'FAILED',
    /**
     * The changeset reconciler ran into a problem while processing the
     * changeset and will retry it for a number of retries.
     */
    RETRYING = 'RETRYING',
    /** The changeset is scheduled, and will be enqueued when its turn comes in Sourcegraph's rollout window. */
    SCHEDULED = 'SCHEDULED',
    /**
     * The changeset reconciler is currently computing the delta between the
     * If a delta exists, the reconciler tries to update the state of the
     * changeset on the code host and on Sourcegraph to the desired state.
     */
    PROCESSING = 'PROCESSING',
    /** The changeset is published, not being reconciled and open on the code host. */
    OPEN = 'OPEN',
    /** The changeset is published, not being reconciled and in draft state on the code host. */
    DRAFT = 'DRAFT',
    /** The changeset is published, not being reconciled and closed on the code host. */
    CLOSED = 'CLOSED',
    /** The changeset is published, not being reconciled and merged on the code host. */
    MERGED = 'MERGED',
    /** The changeset is published, not being reconciled and has been deleted on the code host. */
    DELETED = 'DELETED',
}

/** This enum declares all operations supported by the reconciler. */
export enum ChangesetSpecOperation {
    /** Push a new commit to the code host. */
    PUSH = 'PUSH',
    /**
     * Update the existing changeset on the codehost. This is purely the changeset resource on the code host,
     * not the git commit. For updates to the commit, see 'PUSH'.
     */
    UPDATE = 'UPDATE',
    /** Move the existing changeset out of being a draft. */
    UNDRAFT = 'UNDRAFT',
    /** Publish a changeset to the codehost. */
    PUBLISH = 'PUBLISH',
    /** Publish a changeset to the codehost as a draft changeset. (Only on supported code hosts). */
    PUBLISH_DRAFT = 'PUBLISH_DRAFT',
    /** Sync the changeset with the current state on the codehost. */
    SYNC = 'SYNC',
    /** Import an existing changeset from the code host with the ExternalID from the spec. */
    IMPORT = 'IMPORT',
    /** Close the changeset on the codehost. */
    CLOSE = 'CLOSE',
    /** Reopen the changeset on the codehost. */
    REOPEN = 'REOPEN',
    /** Internal operation to get around slow code host updates. */
    SLEEP = 'SLEEP',
    /** The changeset is removed from some of the associated batch changes. */
    DETACH = 'DETACH',
    /** The changeset is kept in the batch change, but it's marked as archived. */
    ARCHIVE = 'ARCHIVE',
}

/** The type of the changeset spec. */
export enum ChangesetSpecType {
    /** References an existing changeset on a code host to be imported. */
    EXISTING = 'EXISTING',
    /** References a branch and a patch to be applied to create the changeset from. */
    BRANCH = 'BRANCH',
}

/** The state of the batch change. */
export enum BatchChangeState {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
}

/** The available types of jobs that can be run over a batch change. */
export enum BulkOperationType {
    /** Bulk post comments over all involved changesets. */
    COMMENT = 'COMMENT',
    /** Bulk detach changesets from a batch change. */
    DETACH = 'DETACH',
}

/** All valid states a bulk operation can be in. */
export enum BulkOperationState {
    /** The bulk operation is still processing on some changesets. */
    PROCESSING = 'PROCESSING',
    /** No operations are still running and all of them finished without error. */
    COMPLETED = 'COMPLETED',
    /** No operations are still running and at least one of them finished with an error. */
    FAILED = 'FAILED',
}

/** The priority of an email action. */
export enum MonitorEmailPriority {
    NORMAL = 'NORMAL',
    CRITICAL = 'CRITICAL',
}

/** Supported status of monitor events. */
export enum EventStatus {
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

/** The input required to create a code monitor. */
export interface MonitorInput {
    /**
     * The namespace represents the owner of the code monitor.
     * Owners can either be users or organizations.
     */
    namespace: Scalars['ID']
    /** A meaningful description of the code monitor. */
    description: Scalars['String']
    /** Whether the code monitor is enabled or not. */
    enabled: Scalars['Boolean']
}

/** The input required to edit a code monitor. */
export interface MonitorEditInput {
    /** The id of the monitor. */
    id: Scalars['ID']
    /** The desired state after the udpate. */
    update: MonitorInput
}

/** The input required to create a trigger. */
export interface MonitorTriggerInput {
    /** The query string. */
    query: Scalars['String']
}

/** The input required to edit a trigger. */
export interface MonitorEditTriggerInput {
    /** The id of the Trigger. */
    id: Scalars['ID']
    /** The desired state after the udpate. */
    update: MonitorTriggerInput
}

/** The input required to create an action. */
export interface MonitorActionInput {
    /** An email action. */
    email?: Maybe<MonitorEmailInput>
}

/** The input required to create an email action. */
export interface MonitorEmailInput {
    /** Whether the email action is enabled or not. */
    enabled: Scalars['Boolean']
    /** The priority of the email. */
    priority: MonitorEmailPriority
    /** A list of users or orgs which will receive the email. */
    recipients: Array<Scalars['ID']>
    /** Use header to automatically approve the message in a read-only or moderated mailing list. */
    header: Scalars['String']
}

/** The input required to edit an action. */
export interface MonitorEditActionInput {
    /** An email action. */
    email?: Maybe<MonitorEditEmailInput>
}

/** The input required to edit an email action. */
export interface MonitorEditEmailInput {
    /** The id of an email action. */
    id?: Maybe<Scalars['ID']>
    /** The desired state after the update. */
    update: MonitorEmailInput
}

/** The state an LSIF upload can be in. */
export enum LSIFUploadState {
    /** This upload is being processed. */
    PROCESSING = 'PROCESSING',
    /** This upload failed to be processed. */
    ERRORED = 'ERRORED',
    /** This upload was processed successfully. */
    COMPLETED = 'COMPLETED',
    /** This upload is queued to be processed later. */
    QUEUED = 'QUEUED',
    /** This upload is currently being transferred to Sourcegraph. */
    UPLOADING = 'UPLOADING',
}

/** The state an LSIF index can be in. */
export enum LSIFIndexState {
    /** This index is being processed. */
    PROCESSING = 'PROCESSING',
    /** This index failed to be processed. */
    ERRORED = 'ERRORED',
    /** This index was processed successfully. */
    COMPLETED = 'COMPLETED',
    /** This index is queued to be processed later. */
    QUEUED = 'QUEUED',
}

/**
 * An input type that describes a product license to be generated and signed.
 * FOR INTERNAL USE ONLY.
 */
export interface ProductLicenseInput {
    /** The tags that indicate which features are activated by this license. */
    tags: Array<Scalars['String']>
    /** The number of users for which this product subscription is valid. */
    userCount: Scalars['Int']
    /** The expiration date of this product license, expressed as the number of seconds since the epoch. */
    expiresAt: Scalars['Int']
}

/**
 * An input type that describes a product subscription to be purchased. Corresponds to
 * ProductSubscriptionInvoiceItem.
 * FOR INTERNAL USE ONLY.
 */
export interface ProductSubscriptionInput {
    /**
     * The billing plan ID for the subscription (ProductPlan.billingPlanID). This also specifies the
     * billing product, because a plan is associated with its product in the billing system.
     */
    billingPlanID: Scalars['String']
    /** This subscription's user count. */
    userCount: Scalars['Int']
}

/** A new external service. */
export interface AddExternalServiceInput {
    /** The kind of the external service. */
    kind: ExternalServiceKind
    /** The display name of the external service. */
    displayName: Scalars['String']
    /** The JSON configuration of the external service. */
    config: Scalars['String']
    /**
     * The namespace this external service belongs to.
     * Currently, this can only be used for a user.
     */
    namespace?: Maybe<Scalars['ID']>
}

/** Fields to update for an existing external service. */
export interface UpdateExternalServiceInput {
    /** The id of the external service to update. */
    id: Scalars['ID']
    /** The updated display name, if provided. */
    displayName?: Maybe<Scalars['String']>
    /** The updated config, if provided. */
    config?: Maybe<Scalars['String']>
}

/** Describes options for rendering Markdown. */
export interface MarkdownOptions {
    /** A dummy null value (empty input types are not allowed yet). */
    alwaysNil?: Maybe<Scalars['String']>
}

/** The product sources where events can come from. */
export enum EventSource {
    WEB = 'WEB',
    CODEHOSTINTEGRATION = 'CODEHOSTINTEGRATION',
    BACKEND = 'BACKEND',
}

/**
 * Input for Mutation.settingsMutation, which contains fields that all settings (global, organization, and user
 * settings) mutations need.
 */
export interface SettingsMutationGroupInput {
    /** The subject whose settings to mutate (organization, user, etc.). */
    subject: Scalars['ID']
    /**
     * The ID of the last-known settings known to the client, or null if there is none. This field is used to
     * prevent race conditions when there are concurrent editors.
     */
    lastID?: Maybe<Scalars['Int']>
}

/** An edit to a JSON property in a settings JSON object. The JSON property to edit can be nested. */
export interface SettingsEdit {
    /**
     * The key path of the property to update.
     *
     * Inserting into an existing array is not yet supported.
     */
    keyPath: Array<KeyPathSegment>
    /**
     * The new JSON-encoded value to insert. If the field's value is not set, the property is removed. (This is
     * different from the field's value being the JSON null value.)
     *
     * When the value is a non-primitive type, it must be specified using a GraphQL variable, not an inline literal,
     * or else the GraphQL parser will return an error.
     */
    value?: Maybe<Scalars['JSONValue']>
    /**
     * Whether to treat the value as a JSONC-encoded string, which makes it possible to perform an edit that
     * preserves (or adds/removes) comments.
     */
    valueIsJSONCEncodedString?: Maybe<Scalars['Boolean']>
}

/**
 * DEPRECATED: This type was renamed to SettingsEdit.
 * NOTE: GraphQL does not support @deprecated directives on INPUT_FIELD_DEFINITION (input fields).
 */
export interface ConfigurationEdit {
    /** DEPRECATED */
    keyPath: Array<KeyPathSegment>
    /** DEPRECATED */
    value?: Maybe<Scalars['JSONValue']>
    /** DEPRECATED */
    valueIsJSONCEncodedString?: Maybe<Scalars['Boolean']>
}

/**
 * A segment of a key path that locates a nested JSON value in a root JSON value. Exactly one field in each
 * KeyPathSegment must be non-null.
 * For example, in {"a": [0, {"b": 3}]}, the value 3 is located at the key path ["a", 1, "b"].
 */
export interface KeyPathSegment {
    /** The name of the property in the object at this location to descend into. */
    property?: Maybe<Scalars['String']>
    /** The index of the array at this location to descend into. */
    index?: Maybe<Scalars['Int']>
}

/** Input for a user satisfaction (NPS) survey submission. */
export interface SurveySubmissionInput {
    /**
     * User-provided email address, if there is no currently authenticated user. If there is, this value
     * will not be used.
     */
    email?: Maybe<Scalars['String']>
    /** User's likelihood of recommending Sourcegraph to a friend, from 0-10. */
    score: Scalars['Int']
    /** The answer to "What is the most important reason for the score you gave". */
    reason?: Maybe<Scalars['String']>
    /** The answer to "What can Sourcegraph do to provide a better product" */
    better?: Maybe<Scalars['String']>
}

/** Input for a happiness feedback submission. */
export interface HappinessFeedbackSubmissionInput {
    /** User's happiness rating, from 1-4. */
    score: Scalars['Int']
    /** The answer to "What's going well? What could be better?". */
    feedback?: Maybe<Scalars['String']>
    /** The path that the happiness feedback will be submitted from. */
    currentPath?: Maybe<Scalars['String']>
}

/** The version of the search syntax. */
export enum SearchVersion {
    /** Search syntax that defaults to regexp search. */
    V1 = 'V1',
    /** Search syntax that defaults to literal search. */
    V2 = 'V2',
}

/** The search pattern type. */
export enum SearchPatternType {
    literal = 'literal',
    regexp = 'regexp',
    structural = 'structural',
}

/** A specific kind of external service. */
export enum ExternalServiceKind {
    AWSCODECOMMIT = 'AWSCODECOMMIT',
    BITBUCKETCLOUD = 'BITBUCKETCLOUD',
    BITBUCKETSERVER = 'BITBUCKETSERVER',
    GITHUB = 'GITHUB',
    GITLAB = 'GITLAB',
    GITOLITE = 'GITOLITE',
    PERFORCE = 'PERFORCE',
    PHABRICATOR = 'PHABRICATOR',
    OTHER = 'OTHER',
}

/** (experimental) Search context namespace filter types available to narrow down contexts when querying. */
export enum SearchContextsNamespaceFilterType {
    /** Limits available search contexts to instance-level contexts (without a namespace). */
    INSTANCE = 'INSTANCE',
    /** Limits available search contexts to a specific namespace (user or org). */
    NAMESPACE = 'NAMESPACE',
}

/** SearchContextsOrderBy enumerates the ways a search contexts list can be ordered. */
export enum SearchContextsOrderBy {
    SEARCH_CONTEXT_SPEC = 'SEARCH_CONTEXT_SPEC',
    SEARCH_CONTEXT_UPDATED_AT = 'SEARCH_CONTEXT_UPDATED_AT',
}

/** Input for a new search context. */
export interface SearchContextInput {
    /**
     * Search context name. Not the same as the search context spec. Search context namespace and search context name
     * are used to construct the fully-qualified search context spec.
     * Example mappings from search context spec to search context name: global -> global, @user -> user, @org -> org,
     * @user/ctx1 -> ctx1, @org/ctxs/ctx -> ctxs/ctx.
     */
    name: Scalars['String']
    /** Search context description. */
    description: Scalars['String']
    /**
     * Public property controls the visibility of the search context. Public search context is available to
     * any user on the instance. If a public search context contains private repositories, those are filtered out
     * for unauthorized users. Private search contexts are only available to their owners. Private user search context
     * is available only to the user, private org search context is available only to the members of the org, and private
     * instance-level search contexts are available only to site-admins.
     */
    public: Scalars['Boolean']
    /** Namespace of the search context (user or org). If not set, search context is considered instance-level. */
    namespace?: Maybe<Scalars['ID']>
}

/** Input for editing an existing search context. */
export interface SearchContextEditInput {
    /**
     * Search context name. Not the same as the search context spec. Search context namespace and search context name
     * are used to construct the fully-qualified search context spec.
     * Example mappings from search context spec to search context name: global -> global, @user -> user, @org -> org,
     * @user/ctx1 -> ctx1, @org/ctxs/ctx -> ctxs/ctx.
     */
    name: Scalars['String']
    /** Search context description. */
    description: Scalars['String']
    /**
     * Public property controls the visibility of the search context. Public search context is available to
     * any user on the instance. If a public search context contains private repositories, those are filtered out
     * for unauthorized users. Private search contexts are only available to their owners. Private user search context
     * is available only to the user, private org search context is available only to the members of the org, and private
     * instance-level search contexts are available only to site-admins.
     */
    public: Scalars['Boolean']
}

/** Input for a set of revisions to be searched within a repository. */
export interface SearchContextRepositoryRevisionsInput {
    /** ID of the repository to be searched. */
    repositoryID: Scalars['ID']
    /** Revisions in the repository to be searched. */
    revisions: Array<Scalars['String']>
}

/** The type of content in a hunk line. */
export enum DiffHunkLineType {
    /** Added line. */
    ADDED = 'ADDED',
    /** Unchanged line. */
    UNCHANGED = 'UNCHANGED',
    /** Deleted line. */
    DELETED = 'DELETED',
}

/** A specific highlighted line range to fetch. */
export interface HighlightLineRange {
    /**
     * The first line to fetch (0-indexed, inclusive). Values outside the bounds of the file will
     * automatically be clamped within the valid range.
     */
    startLine: Scalars['Int']
    /**
     * The last line to fetch (0-indexed, inclusive). Values outside the bounds of the file will
     * automatically be clamped within the valid range.
     */
    endLine: Scalars['Int']
}

/** Represents the severity level of a diagnostic. */
export enum DiagnosticSeverity {
    ERROR = 'ERROR',
    WARNING = 'WARNING',
    INFORMATION = 'INFORMATION',
    HINT = 'HINT',
}

/**
 * All possible kinds of symbols. This set matches that of the Language Server Protocol
 * (https://microsoft.github.io/language-server-protocol/specification#workspace_symbol).
 */
export enum SymbolKind {
    UNKNOWN = 'UNKNOWN',
    FILE = 'FILE',
    MODULE = 'MODULE',
    NAMESPACE = 'NAMESPACE',
    PACKAGE = 'PACKAGE',
    CLASS = 'CLASS',
    METHOD = 'METHOD',
    PROPERTY = 'PROPERTY',
    FIELD = 'FIELD',
    CONSTRUCTOR = 'CONSTRUCTOR',
    ENUM = 'ENUM',
    INTERFACE = 'INTERFACE',
    FUNCTION = 'FUNCTION',
    VARIABLE = 'VARIABLE',
    CONSTANT = 'CONSTANT',
    STRING = 'STRING',
    NUMBER = 'NUMBER',
    BOOLEAN = 'BOOLEAN',
    ARRAY = 'ARRAY',
    OBJECT = 'OBJECT',
    KEY = 'KEY',
    NULL = 'NULL',
    ENUMMEMBER = 'ENUMMEMBER',
    STRUCT = 'STRUCT',
    EVENT = 'EVENT',
    OPERATOR = 'OPERATOR',
    TYPEPARAMETER = 'TYPEPARAMETER',
}

/** All possible types of Git refs. */
export enum GitRefType {
    /** A Git branch (in refs/heads/). */
    GIT_BRANCH = 'GIT_BRANCH',
    /** A Git tag (in refs/tags/). */
    GIT_TAG = 'GIT_TAG',
    /** A Git ref that is neither a branch nor tag. */
    GIT_REF_OTHER = 'GIT_REF_OTHER',
}

/** Ordering options for Git refs. */
export enum GitRefOrder {
    /** By the authored or committed at date, whichever is more recent. */
    AUTHORED_OR_COMMITTED_AT = 'AUTHORED_OR_COMMITTED_AT',
}

/** All possible types of Git objects. */
export enum GitObjectType {
    /** A Git commit object. */
    GIT_COMMIT = 'GIT_COMMIT',
    /** A Git tag object. */
    GIT_TAG = 'GIT_TAG',
    /** A Git tree object. */
    GIT_TREE = 'GIT_TREE',
    /** A Git blob object. */
    GIT_BLOB = 'GIT_BLOB',
    /** A Git object of unknown type. */
    GIT_UNKNOWN = 'GIT_UNKNOWN',
}

/** The recipient's possible responses to an invitation to join an organization as a member. */
export enum OrganizationInvitationResponseType {
    /** The invitation was accepted by the recipient. */
    ACCEPT = 'ACCEPT',
    /** The invitation was rejected by the recipient. */
    REJECT = 'REJECT',
}

/** RepositoryOrderBy enumerates the ways a repositories list can be ordered. */
export enum RepositoryOrderBy {
    REPOSITORY_NAME = 'REPOSITORY_NAME',
    REPO_CREATED_AT = 'REPO_CREATED_AT',
    /** deprecated (use the equivalent REPOSITORY_CREATED_AT) */
    REPOSITORY_CREATED_AT = 'REPOSITORY_CREATED_AT',
}

/** The possible types of alerts (Alert.type values). */
export enum AlertType {
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
}

/** A user event. */
export enum UserEvent {
    PAGEVIEW = 'PAGEVIEW',
    SEARCHQUERY = 'SEARCHQUERY',
    CODEINTEL = 'CODEINTEL',
    CODEINTELREFS = 'CODEINTELREFS',
    CODEINTELINTEGRATION = 'CODEINTELINTEGRATION',
    CODEINTELINTEGRATIONREFS = 'CODEINTELINTEGRATIONREFS',
    /** Product stages */
    STAGEMANAGE = 'STAGEMANAGE',
    STAGEPLAN = 'STAGEPLAN',
    STAGECODE = 'STAGECODE',
    STAGEREVIEW = 'STAGEREVIEW',
    STAGEVERIFY = 'STAGEVERIFY',
    STAGEPACKAGE = 'STAGEPACKAGE',
    STAGEDEPLOY = 'STAGEDEPLOY',
    STAGECONFIGURE = 'STAGECONFIGURE',
    STAGEMONITOR = 'STAGEMONITOR',
    STAGESECURE = 'STAGESECURE',
    STAGEAUTOMATE = 'STAGEAUTOMATE',
}

/** A period of time in which a set of users have been active. */
export enum UserActivePeriod {
    /** Since today at 00:00 UTC. */
    TODAY = 'TODAY',
    /** Since the latest Monday at 00:00 UTC. */
    THIS_WEEK = 'THIS_WEEK',
    /** Since the first day of the current month at 00:00 UTC. */
    THIS_MONTH = 'THIS_MONTH',
    /** All time. */
    ALL_TIME = 'ALL_TIME',
}

export type ResolveRawRepoNameVariables = Exact<{
    repoName: Scalars['String']
}>

export type ResolveRawRepoNameResult = { repository: Maybe<{ uri: string; mirrorInfo: { cloned: boolean } }> }

export type ExtensionsVariables = Exact<{
    first: Scalars['Int']
    prioritizeExtensionIDs: Array<Scalars['String']>
}>

export type ExtensionsResult = {
    extensionRegistry: {
        extensions: {
            nodes: Array<{
                id: string
                extensionID: string
                url: string
                viewerCanAdminister: boolean
                manifest: Maybe<{ raw: string }>
            }>
        }
    }
}

export type EditSettingsVariables = Exact<{
    subject: Scalars['ID']
    lastID: Maybe<Scalars['Int']>
    edit: ConfigurationEdit
}>

export type EditSettingsResult = {
    configurationMutation: Maybe<{ editConfiguration: Maybe<{ empty: Maybe<{ alwaysNil: Maybe<string> }> }> }>
}

export type OverwriteSettingsVariables = Exact<{
    subject: Scalars['ID']
    lastID: Maybe<Scalars['Int']>
    contents: Scalars['String']
}>

export type OverwriteSettingsResult = {
    settingsMutation: Maybe<{ overwriteSettings: Maybe<{ empty: Maybe<{ alwaysNil: Maybe<string> }> }> }>
}
